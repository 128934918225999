import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean } from 'redux-saga-thunk'

import { Modal, ModalBody } from "reactstrap"
import { useFormik } from 'formik'

import closeIcon from "../../../assets/images/modal-close-icon.svg"
import SelectInputOverlap from '../../../components/Common/SelectInputOverlap';

import { viewSubject as onViewSubject } from '../../../store/subject/actions';
import { createBadge as onCreateBadge } from '../../../store/badges/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const CreateBadgeModal = ({ visible, subject, badges_type, onClose }) => {

    const dispatch = useDispatch()

    const { user, get_success } = useSelector(state => ({
        user: state.LoginReducer.user,
        get_success: fulfilled(state, "CREATE_BADGE"),
    }))

    const initialValues = {
        subject_id: "",
        badge_cat_id: 1,
        badge_typ_id: "",
        description: "",
        title: ""
    }

    const onSubmit = values => {
        let data = {
            user_id: user.id,
            ...formik.values
        }
        dispatch(onCreateBadge(data))
    }

    const validate = values => {

        let errors = {}
    
        if(!values.subject_id) {
          errors.subject_id = "Subject ID required!"
        }

        if(!values.badge_typ_id) {
            errors.badge_typ_id = "Type ID required!"
        }

        if(!values.description) {
            errors.description = "Description required!"
        }

        if(!values.title) {
            errors.title = "Title required!"
        }
        
        return errors
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    }) 

    const handleOnSelectSubject = (id) => {
        formik.setFieldValue("subject_id", id)
    }

    const handleOnSelectType = (id) => {
        formik.setFieldValue("badge_typ_id", id)
    }

    useEffect(() => {
        if(get_success) {
          onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [get_success])

    useEffect(() => {
        dispatch(onViewSubject())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody>
        <div className='create-badges-modal-wrapper'>
            <div className='title'>
                <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
            </div>
            <div className='desp text-start mt-5'>
                <div className='px-4 pt-4'>
                    <div className='d-flex align-items-center mb-4'>
                        <h4>Badge</h4>
                    </div>
                    { visible && 
                        <form className="create-badges-form" name="createRewardForm" onSubmit={formik.handleSubmit}>
                            <div className='select-reward-box'>
                                <SelectInputOverlap
                                    fieldType="name"
                                    fieldLabel="selectBadgesSubject"
                                    data={subject}  
                                    placeholder="Select subject"
                                    design="primary"
                                    onChange={handleOnSelectSubject}
                                />
                                { (formik.touched.subject_id && formik.errors.subject_id) && <p className="mt-1 text-sm text-red-500">{formik.errors.subject_id}</p> }
                            </div>
                            <div className='select-reward-box'>
                                <div className='input-box grey-box mb-0'>
                                    <input className='form-control' type="text" placeholder='Enter badges name' name="title" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.title} />
                                </div>
                                { (formik.touched.title && formik.errors.title) && <p className="mt-1 text-sm text-red-500">{formik.errors.title}</p> }
                            </div>
                            <div className='select-reward-box'>
                                <textarea className='form-control' name="description" placeholder='Badges details' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.description}></textarea>
                                { (formik.touched.description && formik.errors.description) && <p className="mt-1 text-sm text-red-500">{formik.errors.description}</p> }
                            </div>
                            <SelectInputOverlap
                                fieldType="image"
                                fieldLabel="selectBadgesImage"
                                data={badges_type}  
                                placeholder="Select badges image"
                                design="primary"
                                type="image"
                                onChange={handleOnSelectType}
                            />
                            { (formik.touched.badge_typ_id && formik.errors.badge_typ_id) && <p className="mt-1 text-sm text-red-500">{formik.errors.badge_typ_id}</p> }
                            <div className='mt-5'>
                                <button className='btn btn-primary submit-btn'>Save</button>
                                <div className='btn link-btn link-dark-btn ms-3' onClick={onClose}>Cancel</div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}


export default CreateBadgeModal