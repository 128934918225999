import {
    VIEW_SUBJECT,
    VIEW_SUBJECT_SUCCESS,
    VIEW_SUBJECT_FAILED
} from './actionTypes';

export const viewSubject = (subject) => {
    return {
        type: VIEW_SUBJECT,
        payload: subject,
        meta:{
            thunk:true
        }
    }
}
  
export const viewSubjectSuccess = (subject, { thunk }) => ({
    type: VIEW_SUBJECT_SUCCESS,
    payload: subject,
    meta: {
        thunk
    }
})
  
export const viewSubjectFailed = (error, { thunk }) => ({
    type: VIEW_SUBJECT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})