import {
    VIEW_NOTIFICATIONS_SUCCESS,
    VIEW_NOTIFICATIONS_FAILED,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILED,
    VIEW_NOTIFICATION_SETTING_SUCCESS,
    VIEW_NOTIFICATION_SETTING_FAILED,
    UPDATE_NOTIFICATION_SETTING_SUCCESS,
    UPDATE_NOTIFICATION_SETTING_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    notifications: null,
    notification_settings: null
}

const NotificationsReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case VIEW_NOTIFICATIONS_SUCCESS: {
        return {
          ...state,
          loading: false,
          notifications: action.payload,
          error: "",
        }
      }
      case VIEW_NOTIFICATIONS_FAILED: {
        return {
          ...state,
          loading: false,
          notifications: null,
          error: action.payload,
        }
      }
      case UPDATE_NOTIFICATION_SUCCESS: {
        let newItem = action.payload.data
        return {
          ...state,
          notifications: state.notifications.map(item => {
            if(item.id === newItem.id){
              item.read = 'true'
            }
            return item
          })
        }
      }
      case UPDATE_NOTIFICATION_FAILED: {
        return {
          ...state,
          error: action.payload,
        }
      }
      case VIEW_NOTIFICATION_SETTING_SUCCESS: {
        return {
          ...state,
          loading: false,
          notification_settings: action.payload,
          error: "",
        }
      }
      case VIEW_NOTIFICATION_SETTING_FAILED: {
        return {
          ...state,
          loading: false,
          notification_settings: null,
          error: action.payload,
        }
      }
      case UPDATE_NOTIFICATION_SETTING_SUCCESS: {
        return {
          ...state,
          loading: false,
          notification_settings: action.payload,
          error: "",
        }
      }
      case UPDATE_NOTIFICATION_SETTING_FAILED: {
        return {
          ...state,
          loading: false,
          notification_settings: null,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default NotificationsReducer;