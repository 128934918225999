import { call, put, takeEvery } from "redux-saga/effects"

import { GET_KEY_STAGES } from './actionTypes'

import {
    getKeyStagesSuccess,
    getKeyStagesFailed
} from './actions'

import { getKeyStagesApi } from "../../../helpers/api/api"

function* onGetKeyStages({ meta }) {
    try {
      const response = yield call(getKeyStagesApi)
      yield put(getKeyStagesSuccess(response, meta))
    } catch (error) {
      yield put(getKeyStagesFailed(error, meta))
    }
  }

function* KeyStageSaga() {
    yield takeEvery(GET_KEY_STAGES, onGetKeyStages)
}

export default KeyStageSaga