import {
    RESET_PASSWORD_EMAIL,
    RESET_PASSWORD_EMAIL_SUCCESS,
    RESET_PASSWORD_EMAIL_FAILED
} from './actionTypes'

export const resetPasswordEmail = (data) => {
    return {
        type: RESET_PASSWORD_EMAIL,
        payload: data,
        meta:{
            thunk:true
        }
    }
}

export const resetPasswordEmailSuccess = (data, {thunk}) => {
    return {
        type: RESET_PASSWORD_EMAIL_SUCCESS,
        payload: data,
        meta: {
            thunk
        }
    }
}

export const resetPasswordEmailFailed = (error, {thunk}) => {
    return {
        type: RESET_PASSWORD_EMAIL_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}
