import {
    ADD_CARD,
    ADD_CARD_SUCCESS,
    ADD_CARD_FAILED,
    VIEW_CARD,
    VIEW_CARD_SUCCESS,
    VIEW_CARD_FAILED,
    UPDATE_CARD,
    UPDATE_CARD_SUCCESS,
    UPDATE_CARD_FAILED,
    DELETE_CARD,
    DELETE_CARD_SUCCESS,
    DELETE_CARD_FAILED
} from './actionTypes';

export const addCard = (card) => {
    return {
        type: ADD_CARD,
        payload: card,
        meta:{
            thunk:true
        }
    }
}
  
export const addCardSuccess = (card, { thunk }) => ({
    type: ADD_CARD_SUCCESS,
    payload: card,
    meta: {
        thunk
    }
})
  
export const addCardFailed = (error, { thunk }) => ({
    type: ADD_CARD_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const viewCard = (card) => {
    return {
        type: VIEW_CARD,
        payload: card,
        meta:{
            thunk:true
        }
    }
}
  
export const viewCardSuccess = (card, { thunk }) => ({
    type: VIEW_CARD_SUCCESS,
    payload: card,
    meta: {
        thunk
    }
})
  
export const viewCardFailed = (error, { thunk }) => ({
    type: VIEW_CARD_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const updateCard = (card) => {
    return {
        type: UPDATE_CARD,
        payload: card,
        meta:{
            thunk:true
        }
    }
}
  
export const updateCardSuccess = ({response, data}, { thunk }) => ({
    type: UPDATE_CARD_SUCCESS,
    payload: {response, data},
    meta: {
        thunk
    }
})
  
export const updateCardFailed = (error, { thunk }) => ({
    type: UPDATE_CARD_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const deleteCard = (card) => {
    return {
        type: DELETE_CARD,
        payload: card,
        meta:{
            thunk:true
        }
    }
}
  
export const deleteCardSuccess = ({response, data}, { thunk }) => ({
    type: DELETE_CARD_SUCCESS,
    payload: {response, data},
    meta: {
        thunk
    }
})
  
export const deleteCardFailed = (error, { thunk }) => ({
    type: DELETE_CARD_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})