import React from 'react'
import { Modal, ModalBody } from "reactstrap"

import checkImg from "../../assets/images/welcome-check-img.png"
import closeIcon from "../../assets/images/modal-close-icon.svg"

const ContactQueryReceivedModal = ({ visible, onClose }) => {
  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody className='rounded-lg shadow'>
            <div className='content text-center rounded-lg'>
                <div className='title'>
                    <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
                </div>
                <div className='desp text-start mt-5 p-4'>
                    <div className='text-center mb-4'><img src={checkImg} alt="" /></div>
                    <div className='message text-center'>
                        <h4 className='text-gray-950 title mb-3'>Your query has been received</h4>
                        <p className='text-base text-gray-400'>Someone from out team will soon get in touch with you.</p>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
  )
}

export default ContactQueryReceivedModal