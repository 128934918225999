import {
    VIEW_NOTIFICATIONS,
    VIEW_NOTIFICATIONS_SUCCESS,
    VIEW_NOTIFICATIONS_FAILED,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILED,
    VIEW_NOTIFICATION_SETTING,
    VIEW_NOTIFICATION_SETTING_SUCCESS,
    VIEW_NOTIFICATION_SETTING_FAILED,
    UPDATE_NOTIFICATION_SETTING,
    UPDATE_NOTIFICATION_SETTING_SUCCESS,
    UPDATE_NOTIFICATION_SETTING_FAILED
} from './actionTypes'

export const viewNotifications = (notifications) => {
    return {
        type: VIEW_NOTIFICATIONS,
        payload: notifications,
        meta:{
            thunk:true
        }
    }
}

export const viewNotificationSuccess = (plan, {thunk}) => {
    return {
        type: VIEW_NOTIFICATIONS_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const viewNotificationFailed = (plan, {thunk}) => {
    return {
        type: VIEW_NOTIFICATIONS_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const updateNotification = (notification) => {
    return {
        type: UPDATE_NOTIFICATION,
        payload: notification,
        meta:{
            thunk:true
        }
    }
}

export const updateNotificationSuccess = (notification, {thunk}) => {
    return {
        type: UPDATE_NOTIFICATION_SUCCESS,
        payload: notification,
        meta: {
            thunk
        }
    }
}

export const updateNotificationFailed = (error, {thunk}) => {
    return {
        type: UPDATE_NOTIFICATION_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewNotificationSetting = (settings) => {
    return {
        type: VIEW_NOTIFICATION_SETTING,
        payload: settings,
        meta:{
            thunk:true
        }
    }
}

export const viewNotificationSettingSuccess = (settings, {thunk}) => {
    return {
        type: VIEW_NOTIFICATION_SETTING_SUCCESS,
        payload: settings,
        meta: {
            thunk
        }
    }
}

export const viewNotificationSettingFailed = (error, {thunk}) => {
    return {
        type: VIEW_NOTIFICATION_SETTING_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}

export const updateNotificationSetting = (settings) => {
    return {
        type: UPDATE_NOTIFICATION_SETTING,
        payload: settings,
        meta:{
            thunk:true
        }
    }
}

export const updateNotificationSettingSuccess = (settings, {thunk}) => {
    return {
        type: UPDATE_NOTIFICATION_SETTING_SUCCESS,
        payload: settings,
        meta: {
            thunk
        }
    }
}

export const updateNotificationSettingFailed = (error, {thunk}) => {
    return {
        type: UPDATE_NOTIFICATION_SETTING_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}