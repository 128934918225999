import { combineReducers } from "redux"
import { reducer as thunkReducer } from 'redux-saga-thunk'


// Auth
import LoginReducer from "./auth/login/reducer"
import SignupReducer from "./auth/signup/reducer"
import OtpReducer from './auth/otp/reducer'
import ResetPasswordReducer from "./auth/reset-password/reducer"
import ContactAdminReducer from './auth/contact-admin/reducer'

//Plans
import PlansAndPricesReducer from './plans-and-prices/reducer'

// Layout
import LayoutReducer from "./layout/reducer"

//Settings
import GradesReducer from "./settings/grades/reducer"
import KeyStageReducer from "./settings/keystage/reducer"
import ExamBoardsReducer from "./settings/exam-boards/reducer"

//Student
import StudentReducer from "./student/reducer"

//Subject
import SubjectReducer from "./subject/reducer"

//Reward
import RewardReducer from "./reward/reducer"

//Badges
import BadgesReducer from "./badges/reducer"

//RewardType
import RewardTypeReducer from "./reward-type/reducer"

//Lesson
import LessonScoreReducer from "./lesson-score/reducer"

//Card
import CardReducer from "./card/reducer"

//Billing
import BillingReducer from "./billing/reducer"

//Notifications
import NotificationsReducer from "./notifications/reducer"

//Faq
import FaqReducer from "./faq/reducer"

const rootReducer = combineReducers({
    thunk:thunkReducer,
    LayoutReducer,
    SignupReducer,
    OtpReducer,
    ResetPasswordReducer,
    ContactAdminReducer,
    LoginReducer,
    PlansAndPricesReducer,
    GradesReducer,
    KeyStageReducer,
    ExamBoardsReducer,
    StudentReducer,
    SubjectReducer,
    RewardReducer,
    BadgesReducer,
    RewardTypeReducer,
    LessonScoreReducer,
    CardReducer,
    BillingReducer,
    NotificationsReducer,
    FaqReducer
  })
  
  export default rootReducer