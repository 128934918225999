import React, { useState, useEffect } from 'react'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const SelectInput = ({ id, field, data, placeholder, onChange}) => {

  const [ isDropDownExpanded, setDropDownExpanded ] = useState(false)
  const [ selectedItem, setSelectedItem ] = useState("")

  const handleSelectedItem = (item) => {
    setSelectedItem(item[field])
    onChange(item.id)
  }

  useEffect(() => {
    document.addEventListener("mouseup", (event) => {
      const parentElem = document.getElementById(`${id}List`);
      const elem = parentElem?.querySelectorAll(":scope > .select-item")
      if(elem && elem.length !== 0) {
        for (let i = 0; i < elem.length; i++) {
          if (!elem[i].contains(event.target)) {
            setDropDownExpanded(false)
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="select-box">
        <div className="input-box mb-0">
            <div className="input-group shadow">
                <input type="text" className='form-control bg-white border-0 pointer border-radius-0' id={id} placeholder={placeholder} value={selectedItem} onClick={() => {setDropDownExpanded(!isDropDownExpanded)}}  readOnly />
                <span className="input-group-text bg-white">
                    <FontAwesomeIcon className='text-primary-950 text-sm' icon={`fas ${isDropDownExpanded ? "fa-angle-up" : "fa-angle-down"}`} />
                </span>
            </div>
            <ul id={`${id}List`} className={`select-input-dropdown shadow list-group ${isDropDownExpanded ? "d-block" : "d-none"}`}>
              { data?.map((item, index) => {
                return <li key={index} className='list-group-item select-item' onClick={() => {handleSelectedItem(item)}}>{item[field]}</li>
              })
              }
            </ul>
        </div>
    </div>
  )
}

export default SelectInput