import { takeEvery, put, call } from "redux-saga/effects"

import { BUY_PLAN, VIEW_PLANS, VIEW_MY_SUBSCRIPTIONS, UPDATE_RENEWAL, CHANGE_SUBSCRIPTION } from './actionTypes'
import { buyPlanSuccess, buyPlanFailed, viewPlansSuccess, viewPlansFailed, viewSubscriptionsSuccess, viewSubscriptionsFailed, updateRenewalSuccess, updateRenewalFailed, changeSubscriptionSuccess, changeSubscriptionFailed } from './actions'

import { buyPlanApi, viewPlansApi, viewSubscriptionsApi, updateRenewalApi, changeSubscriptionApi } from "../../helpers/api/api"


function* buyPlan({ payload:data , meta }) {
    try {
        const response = yield call(buyPlanApi,data)
        yield put(buyPlanSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(buyPlanFailed(message, meta))
    }
}

function* viewPlans({ meta }) {
    try {
        const response = yield call(viewPlansApi)
        yield put(viewPlansSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewPlansFailed(message, meta))
    }
}

function* viewSubscriptions({ payload:details, meta }) {
    try {
        const response = yield call(viewSubscriptionsApi, details)
        const { data } = response
        yield put(viewSubscriptionsSuccess(data, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewSubscriptionsFailed(message, meta))
    }
}

function* changeSubscription({ payload:data, meta }) {
    try {
        console.log(data)
        const response = yield call(changeSubscriptionApi, data)
        yield put(changeSubscriptionSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(changeSubscriptionFailed(message, meta))
    }
}

function* updateRenewal({ payload: data, meta }) {
    try {
        const response = yield call(updateRenewalApi, data)
        const { message } = response
        yield put(updateRenewalSuccess(message, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateRenewalFailed(message, meta))
    }
}

function* PlanAndPricesSaga() {
    yield takeEvery(BUY_PLAN, buyPlan)
    yield takeEvery(VIEW_PLANS, viewPlans)
    yield takeEvery(VIEW_MY_SUBSCRIPTIONS, viewSubscriptions)
    yield takeEvery(UPDATE_RENEWAL, updateRenewal)
    yield takeEvery(CHANGE_SUBSCRIPTION, changeSubscription)
}

export default PlanAndPricesSaga