export const VIEW_NOTIFICATIONS = "VIEW_NOTIFICATIONS"
export const VIEW_NOTIFICATIONS_SUCCESS = "VIEW_NOTIFICATIONS_SUCCESS"
export const VIEW_NOTIFICATIONS_FAILED = "VIEW_NOTIFICATIONS_FAILED"

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_FAILED = "UPDATE_NOTIFICATION_FAILED"

export const VIEW_NOTIFICATION_SETTING = "VIEW_NOTIFICATION_SETTING"
export const VIEW_NOTIFICATION_SETTING_SUCCESS = "VIEW_NOTIFICATION_SETTING_SUCCESS"
export const VIEW_NOTIFICATION_SETTING_FAILED = "VIEW_NOTIFICATION_SETTING_FAILED"

export const UPDATE_NOTIFICATION_SETTING = "UPDATE_NOTIFICATION_SETTING"
export const UPDATE_NOTIFICATION_SETTING_SUCCESS = "UPDATE_NOTIFICATION_SETTING_SUCCESS"
export const UPDATE_NOTIFICATION_SETTING_FAILED = "UPDATE_NOTIFICATION_SETTING_FAILED"