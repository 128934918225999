import { takeEvery, put, call } from "redux-saga/effects"

import { RESET_PASSWORD_EMAIL } from './actionTypes'
import { resetPasswordEmailSuccess, resetPasswordEmailFailed } from './actions'

import { resetPasswordEmailApi } from "../../../helpers/api/api"

function* resetPasswordEmail({ payload: email , meta }) {
    try {
        let data = {
            email,
            redirect_url: "https://test.smartht.co.uk"
        }
        console.log(data)
        const response = yield call(resetPasswordEmailApi,data)
        yield put(resetPasswordEmailSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(resetPasswordEmailFailed(message, meta))
    }
}

function* ResetPasswordSaga() {
    yield takeEvery(RESET_PASSWORD_EMAIL, resetPasswordEmail)
}

export default ResetPasswordSaga