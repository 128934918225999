import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import closeIcon from "../../../../assets/images/modal-close-icon.svg"

const TermsPrivacyModal = ({ visible, onClose }) => {
  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody>
          <div className='content terms-wrapper text-center'>
            <div className='title'>
              <h3 className='mt-2 mb-5 fw-regular ft-default text-primary-900'>Terms and Conditions</h3>
              <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
            </div>
            <ul className='list'>
              <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis adipisci dolore enim hic consequuntur dolores laboriosam itaque earum saepe, aperiam doloribus? Molestiae, non incidunt tempora autem cumque enim eum ea?</li>
              <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae tempore fugit aut temporibus dolorum? Minus debitis eaque, nihil quasi quos, illo alias eos, vero hic harum aspernatur sunt expedita rem.</li>
            </ul>
          </div>
        </ModalBody>
    </Modal>
  )
}


export default TermsPrivacyModal