import { call, put, takeEvery } from "redux-saga/effects"

import { LOGIN_USER, LOGOUT_USER, VIEW_USER_PROFILE, UPDATE_USER_PROFILE } from "./actionTypes"
import { loginSuccess, loginFailed, logoutUserSuccess, logoutUserFailed, viewProfileSuccess, viewProfileFailed, updateProfileSuccess, updateProfileFailed } from "./actions"

import { loginApi, viewUserApi, updateUserApi } from '../../../helpers/api/api'
import { sessionData } from "../../../constants/sessiondata"

function* loginUser({ payload: { user }, meta }) {
    try {
        const response = yield call(loginApi,{
            email: user.email,
            password: user.password,
        })
        const { token_type, token } = response
        sessionData.setAuthUser(JSON.stringify(`${token_type} ${token}`))
        yield put(loginSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(loginFailed(message, meta))
    }
    
}

function* logoutUser({ payload: {navigate, type} }) {
    try {
      //const response = yield call(logoutApi);
      sessionData.removeAuthUser();
      sessionData.removeParentId();
      sessionData.removeEmailVerified();
      if(type === 'student') {
        navigate("/sign-in?as=student")
      }
      else {
        navigate("/sign-in")
      }
      yield put(logoutUserSuccess({}))
    } catch (error) {
      yield put(logoutUserFailed(error))
    }
  }

function* viewUserProfile({payload, meta}) {
  try {
    const response = yield call(viewUserApi,{
      id: payload
    })
    yield put(viewProfileSuccess(response, meta))
  }
  catch(error) {
    const {message}=error.response.data
    yield put(viewProfileFailed(message, meta))
  }
}

function* updateUserProfile({ payload: data, meta }) {
  try {
    const response = yield call(updateUserApi,data)
    yield put(updateProfileSuccess(response, meta))
  }
  catch(error) {
    const data = error.response.data.message
    yield put(updateProfileFailed(data, meta))
  }
}

function* loginSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeEvery(LOGOUT_USER, logoutUser)
    yield takeEvery(VIEW_USER_PROFILE, viewUserProfile)
    yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile)
}
  
  export default loginSaga