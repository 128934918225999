import {
    GET_GRADES_SUCCESS,
    GET_GRADES_FAILED
} from './actionTypes'

const INIT_STATE = {
    grades: [],
    error: "",
    form_errors: []
}

const GradesReducer = (state = INIT_STATE, { type, payload }) => {
    
    switch (type) {
        
        case GET_GRADES_SUCCESS: {
            return {
                ...state,
                grades: payload
            }
        }

        case GET_GRADES_FAILED: {
            return {
                ...state,
                error: payload
            }
        }
        default:
            return state
    }
}

export default GradesReducer