import {
  LOGIN_SUCCESS,
  LOGOUT_USER_SUCCESS,
  LOGIN_FAILED,
  VIEW_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED
} from './actionTypes'

import { sessionData } from '../../../constants/sessiondata'

const initialState = {
    error: "",
    loading: false,
    user: {}
}

const LoginReducer = (state = initialState, action) => {
    
  switch (action.type) {
      
      case LOGIN_SUCCESS:{
        const { user } = action.payload
        sessionData.setParentId(user.id)
        return {
          ...state,
          loading: false,
          user
        }
      }
      case LOGOUT_USER_SUCCESS: {
        const { user } = action.payload
        return {
          ...state,
          user,
          error: ""
        }
      }
      case VIEW_USER_PROFILE_SUCCESS:{
        const {user} = action.payload
        //console.log('viewprofile', user)
        return {
          ...state,
          loading: false,
          user
        }
      }
      case UPDATE_USER_PROFILE_FAILED:{
        return {
          ...state,
          error: action.payload
        }
      }
      case LOGIN_FAILED: {
        return {
          ...state,
          error: action.payload
        }
      }
      default:
        return state
    }

  }
  
  export default LoginReducer