import {
    TOGGLE_SIDEBAR
} from './actionTypes'

const INIT_STATE = {
    isSideBarExpanded: false
}

const LayoutReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isSideBarExpanded: action.payload
            }

        default:
            return state
    }
}

export default LayoutReducer