import React, { useState, useEffect } from 'react'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const SelectInputOverlap = ({ fieldType, fieldLabel, fieldId, data, placeholder, design, type, onChange}) => {

  const [ isDropDownExpanded, setDropDownExpanded ] = useState(false)
  const [ selectedItem, setSelectedItem ] = useState("")

  const handleSelectedItem = (item) => {
    setSelectedItem(item[fieldType])
    onChange(item.id)
  }

  useEffect(() => {
    document.addEventListener("mouseup", (event) => {
      const parentElem = document.getElementById(`${fieldLabel}List`);
      const elem = parentElem?.querySelectorAll(":scope > .select-item")
      if(elem && elem.length !== 0) {
        for (let i = 0; i < elem.length; i++) {
          if (!elem[i].contains(event.target)) {
            setDropDownExpanded(false)
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(fieldId) {
      let item = data.filter(res => {
        return res.id === fieldId
      })
      setSelectedItem(item[0][fieldType])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId])

  return (
    <div className={`select-overlap-box ${design}`}>
        <div className="input-box mb-0">
            <div className="input-group relative">
                <input type="text" className='form-control bg-white border-0 pointer border-radius-0' id={fieldLabel} placeholder={placeholder} value={selectedItem} onClick={() => {setDropDownExpanded(!isDropDownExpanded)}}  readOnly />
                { type === 'image' && selectedItem !== "" && 
                <div className='image-input' onClick={() => {setDropDownExpanded(!isDropDownExpanded)}}><img className='image-select' src={selectedItem} alt="" /></div> 
                }
                <span className="input-group-text bg-white">
                    <FontAwesomeIcon className='text-gray-900 text-sm' icon={`fas ${isDropDownExpanded ? "fa-angle-up" : "fa-angle-down"}`} />
                </span>
            </div>
            <ul id={`${fieldLabel}List`} className={`select-input-dropdown list-group ${isDropDownExpanded ? "d-block" : "d-none"} ${type === 'image' ? "image-dropdown" : ""} `}>
              { data?.map((item, index) => {
                return (
                  <>
                  { type !== 'image' ? <li key={index} className='list-group-item select-item' onClick={() => {handleSelectedItem(item)}}>{item[fieldType]}</li> : <li key={index} className='list-group-item select-item image-item' onClick={() => {handleSelectedItem(item)}}>
                    <img src={item[fieldType]} alt="" />
                  </li> }
                  </>
                )
              })
              }
            </ul>
        </div>
    </div>
  )
}

export default SelectInputOverlap