import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap"


const RewardDeleteConfirmModal = ({ visible, onCloseConfirmDelete, onSubmitData }) => {

  return (
    <Modal isOpen={visible} toggle={onCloseConfirmDelete} size='lg'>
        <ModalBody>
        <div className='signout-confirm-wrapper m-auto'>
            <h4 className='text-primary-800 fw-regular ft-default mb-4'>Delete Reward</h4>
            <h5 className='text-gray-600 fw-regular ft-default mb-5'>Are you sure you want to delete this reward?</h5>
            <div className='button-box d-md-flex'>
                <div className='btn btn-secondary' onClick={onCloseConfirmDelete}>Cancel</div>
                <div className='btn btn-primary' onClick={onSubmitData}>Yes</div>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}


export default RewardDeleteConfirmModal