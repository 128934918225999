import {
    BUY_PLAN, 
    BUY_PLAN_SUCCESS,
    BUY_PLAN_FAILED,
    VIEW_MY_SUBSCRIPTIONS,
    VIEW_MY_SUBSCRIPTIONS_SUCCESS,
    VIEW_MY_SUBSCRIPTIONS_FAILED,
    UPDATE_RENEWAL_SUCCESS,
    UPDATE_RENEWAL_FAILED,
    CHANGE_SUBSCRIPTION_SUCCESS,
    CHANGE_SUBSCRIPTION_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    plan: null,
    subscriptions: null,
    autorenewal: ""
}


const PlansAndPricesReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case BUY_PLAN: {
        return {
          ...state,
          loading: true,
          error: "",
        }
      }
      case BUY_PLAN_SUCCESS: {
        return {
          ...state,
          loading: false,
          plan: action.payload,
          error: "",
        }
      }
      case BUY_PLAN_FAILED: {
        return {
          ...state,
          loading: false,
          plan: null,
          error: action.payload,
        }
      }
      case VIEW_MY_SUBSCRIPTIONS: {
        return {
          ...state,
          loading: true,
          error: "",
        }
      }
      case VIEW_MY_SUBSCRIPTIONS_SUCCESS: {
        return {
          ...state,
          loading: false,
          subscriptions: action.payload,
          error: "",
        }
      }
      case VIEW_MY_SUBSCRIPTIONS_FAILED: {
        return {
          ...state,
          loading: false,
          subscriptions: null,
          error: action.payload,
        }
      }
      case CHANGE_SUBSCRIPTION_SUCCESS: {
        return {
          ...state,
          loading: false,
          subscriptions: action.payload,
          error: "",
        }
      }
      case CHANGE_SUBSCRIPTION_FAILED: {
        return {
          ...state,
          loading: false,
          subscriptions: null,
          error: action.payload,
        }
      }
      case UPDATE_RENEWAL_SUCCESS: {
        return {
          ...state,
          autorenewal: action.payload
        }
      }
      case UPDATE_RENEWAL_FAILED: {
        return {
          ...state,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default PlansAndPricesReducer;