import {
    VIEW_REWARD_TYPE,
    VIEW_REWARD_TYPE_SUCCESS,
    VIEW_REWARD_TYPE_FAILED
} from './actionTypes'

export const viewRewardType = () => {
    return {
        type: VIEW_REWARD_TYPE,
        meta:{
            thunk:true
        }
    }
}

export const viewRewardTypeSuccess = (reward_type, {thunk}) => {
    return {
        type: VIEW_REWARD_TYPE_SUCCESS,
        payload: reward_type,
        meta: {
            thunk
        }
    }
}

export const viewRewardTypeFailed = (reward_type, {thunk}) => {
    return {
        type: VIEW_REWARD_TYPE_FAILED,
        payload: reward_type,
        error: true,
        meta: {
            thunk
        }
    }
}