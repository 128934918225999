import {
    VIEW_BILLING_INFO,
    VIEW_BILLING_INFO_SUCCESS,
    VIEW_BILLING_INFO_FAILED,
    ADD_BILLING_INFO,
    ADD_BILLING_INFO_SUCCESS,
    ADD_BILLING_INFO_FAILED,
    UPDATE_BILLING_INFO,
    UPDATE_BILLING_INFO_SUCCESS,
    UPDATE_BILLING_INFO_FAILED
} from './actionTypes';

export const viewBilling = (bill) => {
    return {
        type: VIEW_BILLING_INFO,
        payload: bill,
        meta:{
            thunk:true
        }
    }
}
  
export const viewBillingSuccess = (data, { thunk }) => {
    return {
        type: VIEW_BILLING_INFO_SUCCESS,
        payload: data,
        meta: {
            thunk
        }
    }
}
  
export const viewBillingFailed = (error, { thunk }) => ({
    type: VIEW_BILLING_INFO_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const addBillingInfo = (bill) => {
    return {
        type: ADD_BILLING_INFO,
        payload: bill,
        meta:{
            thunk:true
        }
    }
}
  
export const addBillingInfoSuccess = ({response, data}, { thunk }) => ({
    type: ADD_BILLING_INFO_SUCCESS,
    payload: {response, data},
    meta: {
        thunk
    }
})
  
export const addBillingInfoFailed = (error, { thunk }) => ({
    type: ADD_BILLING_INFO_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const updateBillingInfo = (bill) => {
    return {
        type: UPDATE_BILLING_INFO,
        payload: bill,
        meta:{
            thunk:true
        }
    }
}
  
export const updateBillingInfoSuccess = (data, { thunk }) => ({
    type: UPDATE_BILLING_INFO_SUCCESS,
    payload: data,
    meta: {
        thunk
    }
})
  
export const updateillingInfoFailed = (error, { thunk }) => ({
    type: UPDATE_BILLING_INFO_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})