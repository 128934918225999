import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from "reactstrap"

import closeIcon from "../../../assets/images/modal-close-icon.svg"

const CancelSubscriptionModal = ({ visible, onClose } ) => {
  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody className='rounded-lg shadow'>
        <div className='content text-center bg-primary-100 rounded-lg shadow'>
            <div className='title'>
                <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
            </div>
            <div className='desp text-start mt-5 p-4'>
                <h4 className='text-gray-950 mb-5 ft-default'>Oh! You want to cancel subscription early?</h4>
                <p className='text-gray-700 text-h6 ft-default pb-5'>You are currently subscribed to annual pro plan, which offers discounts. Cancellation of subscription early not incur plan price adjustment If you cancel your subscription your students account also will be deleted. To cancel , visit to our enquiry page</p>
                <Link to="/parent/help" className='btn btn-primary rounded-lg content-btn'>Go to FAQ</Link>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}

export default CancelSubscriptionModal