import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_REWARD_TYPE } from './actionTypes'
import { viewRewardTypeSuccess, viewRewardTypeFailed } from './actions'

import { viewRewardTypeApi } from "../../helpers/api/api"


function* viewRewardType({ meta }) {
    try {
        const response = yield call(viewRewardTypeApi)
        yield put(viewRewardTypeSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewRewardTypeFailed(message, meta))
    }
}

function* RewardTypeSaga() {
    yield takeEvery(VIEW_REWARD_TYPE, viewRewardType)
}

export default RewardTypeSaga

