import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILED,
    VIEW_USER_PROFILE,
    VIEW_USER_PROFILE_SUCCESS,
    VIEW_USER_PROFILE_FAILED,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAILED
} from './actionTypes'

export const loginUser = (user, navigate) => {
    return {
      type: LOGIN_USER,
      payload: { user, navigate },
      meta:{
        thunk:true
      }
    }
}

export const loginSuccess = (user, {thunk}) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
    meta: {
      thunk
    }
  }
}

export const loginFailed = (error, {thunk}) => {
  return {
    type: LOGIN_FAILED,
    payload: error,
    error:true,
    meta: {
      thunk
    }
  }
}

export const logoutUser = (navigate, type) => {
  return {
    type: LOGOUT_USER,
    payload: { navigate, type },
  }
}

export const logoutUserSuccess = user => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: user,
  }
}

export const logoutUserFailed = (error, {thunk}) => {
  return {
    type: LOGOUT_USER_FAILED,
    payload: error,
    error:true,
    meta: {
      thunk
    }
  }
}

export const viewProfile = (data) => {
  return {
    type: VIEW_USER_PROFILE,
    payload: data,
    meta:{
        thunk:true
    }
  }   
}

export const viewProfileSuccess = (user, {thunk}) => {
  return {
    type: VIEW_USER_PROFILE_SUCCESS,
    payload: user,
    meta: {
      thunk
    }
  }
}

export const viewProfileFailed = (error, {thunk}) => {
  return {
    type: VIEW_USER_PROFILE_FAILED,
    payload: error,
    error:true,
    meta: {
      thunk
    }
  }
}

export const updateProfile = (data) => {
  console.log(data)
  return {
    type: UPDATE_USER_PROFILE,
    payload: data,
    meta:{
        thunk:true
    }
  }   
}

export const updateProfileSuccess = (user, {thunk}) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: user,
    meta: {
      thunk
    }
  }
}

export const updateProfileFailed = (error, {thunk}) => {
  return {
    type: UPDATE_USER_PROFILE_FAILED,
    payload: error,
    error:true,
    meta: {
      thunk
    }
  }
}




