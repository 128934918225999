
// Authentication
export const PARENT_LOGIN = "/parentauth/login"

// Signup
export const PARENT_SIGNUP = "/parentauth/signup"

// Otp
export const VERIFY_OTP = "/parentauth/verifyOtp"
export const RESEND_OTP = "/parentauth/requestOtp"

//Reset
export const RESET_PASSWORD_EMAIL = "/auth/recovery"

//Contact
export const CONTACT_ADMIN = "/contact"

//View user
export const VIEW_USER_PROFILE = "/parentprofile/viewProfile"
export const UPDATE_USER_PROFILE = "/parentprofile/updateProfile"

//Notification
export const VIEW_NOTIFICATIONS = "/viewNotificationParent"
export const UPDATE_NOTIFICATION = "/readNotification"
export const VIEW_NOTIFICATION_SETTING = "/viewStudentNotificationSetting"
export const UPDATE_NOTIFICATION_SETTING = "/updateStudentNotificationSetting"

//Plan
export const BUY_PLAN = "/buyPlan"
export const VIEW_PLANS = "/viewMyPlans"
export const VIEW_MY_SUBSCRIPTIONS = "/viewMySubscriptionPlans"
export const UPDATE_RENEWAL = "/renewalOnOff"
export const CHANGE_SUBSCRIPTION = "/changePlan"

// Settings
export const GET_GRADES = "/viewStudentYear"
export const GET_KEY_STAGES = "/viewStudentKeyStage"
export const GET_EXAM_BOARDS = "/viewStudentExamBoard"

//Student
export const ADD_STUDENT = "/createStudentByParent"
export const VIEW_STUDENT = "/viewStudentByParent"
export const UPDATE_STUDENT = "/updateStudentByParent"
export const DELETE_STUDENT = "/deleteStudentByParent"

//Subject
export const VIEW_SUBJECT = "/viewParentSubject"

//REWARD
export const VIEW_REWARD = "/viewReward"
export const VIEW_REWARD_CATEGORY = "/viewParentRewardCategory"
export const CREATE_REWARD = "/createReward"
export const UPDATE_REWARD = "/updateReward"
export const DELETE_REWARD = "/deleteReward"

//BADGES
export const VIEW_BADGES = "/viewBadge"
export const VIEW_BADGES_TYPE = "/viewParentBadgeType"
export const CREATE_BADGE = "/createBadge"

//REWARD TYPE
export const VIEW_REWARD_TYPE = "/viewParentRewardType"

//LESSON SCORE
export const VIEW_EXAM_TYPE = "/viewAllExamType"
export const VIEW_CHAPTERS = "/viewAllParentChapter"

//Card
export const ADD_CARD = "/createCard"
export const VIEW_CARD = "/viewCard"
export const UPDATE_CARD = "/updateCard"
export const DELETE_CARD = "/deleteCard"

//Billing
export const ADD_BILLING_INFO = "/createBilling"
export const VIEW_BILLING_INFO = "/viewBilling"
export const UPDATE_BILLING_INFO = "/updateBilling"

//Faq
export const VIEW_FAQ = "/viewFaqParent"

//Logout
export const PARENT_LOGOUT = "/parentauth/logout"
