import {
    VIEW_SUBJECT_SUCCESS,
    VIEW_SUBJECT_FAILED
} from './actionTypes'

const initialState = {
    error: "",
    loading: false,
    subject: {}
}

const SubjectReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case VIEW_SUBJECT_SUCCESS:{
          const subject = action.payload
          return {
            ...state,
            loading: false,
            subject
          }
        }
        case VIEW_SUBJECT_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        default:
          return state
      }
  
}
    
export default SubjectReducer