import {
    VIEW_PLANS,
    VIEW_PLANS_SUCCESS,
    VIEW_PLANS_FAILED,
    BUY_PLAN,
    BUY_PLAN_SUCCESS,
    BUY_PLAN_FAILED,
    VIEW_MY_SUBSCRIPTIONS,
    VIEW_MY_SUBSCRIPTIONS_SUCCESS,
    VIEW_MY_SUBSCRIPTIONS_FAILED,
    UPDATE_RENEWAL,
    UPDATE_RENEWAL_SUCCESS,
    UPDATE_RENEWAL_FAILED,
    CHANGE_SUBSCRIPTION,
    CHANGE_SUBSCRIPTION_SUCCESS,
    CHANGE_SUBSCRIPTION_FAILED
} from './actionTypes'

export const viewPlans = () => {
    return {
        type: VIEW_PLANS,
        meta:{
            thunk:true
        }
    }
}

export const viewPlansSuccess = (plan, {thunk}) => {
    return {
        type: VIEW_PLANS_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const viewPlansFailed = (plan, {thunk}) => {
    return {
        type: VIEW_PLANS_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const buyPlan = (plan) => {
    return {
        type: BUY_PLAN,
        payload: plan,
        meta:{
            thunk:true
        }
    }
}

export const buyPlanSuccess = (plan, {thunk}) => {
    return {
        type: BUY_PLAN_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const buyPlanFailed = (plan, {thunk}) => {
    return {
        type: BUY_PLAN_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewSubscriptions = (plan) => {
    return {
        type: VIEW_MY_SUBSCRIPTIONS,
        payload: plan,
        meta:{
            thunk:true
        }
    }
}

export const viewSubscriptionsSuccess = (plan, {thunk}) => {
    return {
        type: VIEW_MY_SUBSCRIPTIONS_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const viewSubscriptionsFailed = (plan, {thunk}) => {
    return {
        type: VIEW_MY_SUBSCRIPTIONS_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const changeSubscription = (plan) => {
    return {
        type: CHANGE_SUBSCRIPTION,
        payload: plan,
        meta:{
            thunk:true
        }
    }
}

export const changeSubscriptionSuccess = (plan, {thunk}) => {
    return {
        type: CHANGE_SUBSCRIPTION_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const changeSubscriptionFailed = (plan, {thunk}) => {
    return {
        type: CHANGE_SUBSCRIPTION_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const updateRenewal = (renewal) => {
    return {
        type: UPDATE_RENEWAL,
        payload: renewal,
        meta:{
            thunk:true
        }
    }
}

export const updateRenewalSuccess = (renewal, {thunk}) => {
    return {
        type: UPDATE_RENEWAL_SUCCESS,
        payload: renewal,
        meta: {
            thunk
        }
    }
}

export const updateRenewalFailed = (error, {thunk}) => {
    return {
        type: UPDATE_RENEWAL_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}