import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fulfilled } from 'redux-saga-thunk'

import PaymentCard from './PaymentCard'

import AddBilling from '../../../components/Common/AddBilling'
import AddCard from '../../../components/Common/AddCard'

import DeleteCardComfirmModal from './DeleteCardComfirmModal'

import { viewCard as onViewCard, deleteCard as onDeleteCard } from '../../../store/card/actions'
import { viewBilling as onViewBilling } from '../../../store/billing/actions'


const BillingSettings = (props) => {

  const dispatch = useDispatch();

  const [ isUpdateBillingInfo, setUpdateBillingInfo ] = useState(false)
  const [ isAddCardVisible, setAddCardVisible ] = useState(false)

  const [ isDeletelModalVisible, setDeletelModalVisible ] = useState(false)
  const [ selectedDeleteId, setSelectedDeleteId ] = useState(null);
  const  [ cardDetails, setCardDetails ] = useState([])

  const { user, cards, get_user_success, get_cards_success, delete_card_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    cards: state.CardReducer.cards,
    get_user_success: fulfilled(state, "VIEW_USER_PROFILE"),
    get_cards_success: fulfilled(state, "VIEW_CARD"),
    delete_card_success: fulfilled(state, "DELETE_CARD")
  }))

  const handleDeleteCardConfirm = (id) => {
    setDeletelModalVisible(true)
    setSelectedDeleteId(id)
  }
  
  const handleDeleteCard = () => {
    setDeletelModalVisible(false)
    let data = {
      id: selectedDeleteId
    }
    dispatch(onDeleteCard(data))
  }

  const showBillingInfo = () => {
    setUpdateBillingInfo(true)
    setAddCardVisible(false)
  }

  const hideBillingInfo = () => {
    setUpdateBillingInfo(false)
    setAddCardVisible(false)
  }

  const hideAddCard = () => {
    setAddCardVisible(false)
    setUpdateBillingInfo(true)
  }

  const refreshCardsAndBilling = () => {
    let data = {
      user_id: user.id
    }
    dispatch(onViewCard(data))
    dispatch(onViewBilling(data))
  }

  const resetData = () => {
    setUpdateBillingInfo(false)
    setAddCardVisible(false)
    refreshCardsAndBilling()
  }

  useEffect(() => {
    if(get_user_success) {
      refreshCardsAndBilling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_user_success])


  useEffect(() => {
    if(get_cards_success) {
      setCardDetails(cards)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_cards_success])

  useEffect(() => {
    if(delete_card_success) {
      setCardDetails(cards)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delete_card_success])

  return (
    <div className='billing-settings-wrapper'>
      <div className='row'>
        <div className='col-sm-12'>

        <div className='card gray-card'>
          <div className='card-header'><h4 className='ft-default fw-regular'>Card Details</h4></div>
            <div className='card-body'>
              <div className='row'>
                { !isUpdateBillingInfo ? (
                  <div className='col-md-6'>
                    {
                      cardDetails.map((item, index) => {
                        return (
                          <PaymentCard key={index} index={index} card={item} onDelete={handleDeleteCardConfirm} />
                        )
                      })
                    }
                    <div className='my-4 billing-info d-flex align-items-center pointer'>
                      <p className='ms-2 text-primary-950 text-base' onClick={showBillingInfo}>Update billing info</p>
                    </div>
                  </div>
                ) : (
                  <div className='col-md-8'>
                    {!isAddCardVisible ? (
                      <AddBilling isEdit={true} onClose={hideBillingInfo} onSubmitData={() => {setAddCardVisible(true)}} />
                    ) : (
                      <AddCard setup={false} card={cardDetails[0]} onSubmitData={resetData} onClose={hideAddCard} />
                    )}
                  </div> 
                )}
              </div>
          </div>
        </div>
          <DeleteCardComfirmModal visible={isDeletelModalVisible} 
            onClose = {() => {setDeletelModalVisible(!isDeletelModalVisible)}}
            onDelete = {handleDeleteCard}
            />
      </div>
    </div>
  </div>
  )
}

export default BillingSettings