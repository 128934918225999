import React, { useState, useEffect } from 'react'
import { NavLink, Outlet  } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled } from 'redux-saga-thunk';

import SelectInputOverlap from '../../components/Common/SelectInputOverlap';
import { viewStudent as onViewStudent } from '../../store/student/actions';


const Growth = props => {

  const dispatch = useDispatch()

  const [ availableStudents, setAvailableStudents ] = useState([])
  const [ isStudentSelected, setStudentSelected ] = useState(false)

  const { user, students, get_student } = useSelector(state => ({
    user: state.LoginReducer.user,
    students: state.StudentReducer.student,
    get_student: fulfilled(state, "VIEW_STUDENT")
  }))

  const handleStudent = (id) => {
    if(id != null) {
      setStudentSelected(true)
    }
  }
  
  useEffect(() => {
    if(user) {
      let data = { parent_id:user.id }
      dispatch(onViewStudent(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(get_student) {
      setAvailableStudents(students)
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [students])

  return (
    <div className='growth-wrapper'>
      <h5 className='mb-3'>Growth</h5>
      <div className='growth-container'>
        <div className='tabs-box'>
        <SelectInputOverlap
          fieldType="full_name"
          data={availableStudents}  
          placeholder="Select student"
          onChange={handleStudent}
          />

          {  isStudentSelected && <>
            <NavLink to="/parent/growth/score" activeClassName="active" className='btn tab-btn me-3'>Score</NavLink>
            <NavLink to="/parent/growth/reward" activeClassName="active" className='btn tab-btn me-3'>Reward</NavLink>
            <NavLink to="/parent/growth/badges" activeClassName="active" className='btn tab-btn me-3'>Badges</NavLink>
          </>    }
        </div>
        <div className='tabs-content'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Growth