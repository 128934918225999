import {
    RESET_PASSWORD_EMAIL, RESET_PASSWORD_EMAIL_SUCCESS, RESET_PASSWORD_EMAIL_FAILED
  } from './actionTypes'
  
  
  const INIT_STATE = {
    error: null,
    message: null,
    loading: false,
    email: null,
  }
  
  const ResetPasswordReducer = (state = INIT_STATE, action) => {
  
      switch (action.type) {
        
        case RESET_PASSWORD_EMAIL: {
          return {
            ...state,
            loading: true,
            error: "",
          }
        }
        case RESET_PASSWORD_EMAIL_SUCCESS: {
          console.log(action.payload)
          const email = action.payload
          return {
            ...state,
            loading: false,
            email,
            error: "",
          }
        }
        case RESET_PASSWORD_EMAIL_FAILED: {
          return {
            ...state,
            loading: false,
            email: null,
            error: action.payload,
          }
        }
        default:
          return state
      }
  
  }
  
  export default ResetPasswordReducer;