import React from 'react'

import AccountStepper from '../AccountStepper/AccountStepper'
import AddBilling from '../../../../components/Common/AddBilling'
import PaymentSummary from '../../../../components/Common/PaymentSummary'

const BillingInfo = props => {
  return (
    <div className='billing-info-wrapper'>
        <AccountStepper />
        <div className='row'>
            <div className='col-md-7'>
                <div className='card gray-card h-100'>
                    <div className='card-header'><h4 className='ft-default fw-regular'>Add Billing Information</h4></div>
                    <div className='card-body p-4'>
                        <AddBilling />
                    </div>
                </div>
            </div>
            <div className='col-md-5'>
            <div className='card gray-card summary-card h-100'>
                    <div className='card-header'><h4 className='ft-default fw-regular'>Summary</h4></div>
                    <div className='card-body p-4'>
                        <PaymentSummary />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}


export default BillingInfo