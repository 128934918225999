import React from 'react'

import AccountStepper from '../AccountStepper/AccountStepper'
import AddCard from '../../../../components/Common/AddCard'
import PaymentSummary from '../../../../components/Common/PaymentSummary'

const Payment = props => {
  return (
    <div className='payment-info-wrapper'>
        <AccountStepper />
        <div className='row'>
            <div className='col-md-7'>
                <div className='card gray-card h-100'>
                    <div className='card-header'><h4 className='ft-default fw-regular'>Select payment method</h4></div>
                    <div className='card-body p-4'>
                        <AddCard setup={true} />
                    </div>
                </div>
            </div>
            <div className='col-md-5'>
            <div className='card gray-card summary-card h-100'>
                    <div className='card-header'><h4 className='ft-default fw-regular'>Summary</h4></div>
                    <div className='card-body p-4'>
                        <PaymentSummary />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Payment