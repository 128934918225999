import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_BADGES, VIEW_BADGES_TYPE, CREATE_BADGE } from './actionTypes'
import { viewBadgesSuccess, viewBadgesFailed, viewBadgesTypeSuccess, viewBadgesTypeFailed, createBadgeSuccess, createBadgeFailed } from './actions'

import { viewBadgesApi, viewBadgesTypeApi, createBadgeApi } from "../../helpers/api/api"

function* viewBadges({ payload:details, meta }) {
    try {
        const response = yield call(viewBadgesApi, details)
        yield put(viewBadgesSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewBadgesFailed(message, meta))
    }
}

function* viewBadgesType({ meta }) {
    try {
        const response = yield call(viewBadgesTypeApi)
        yield put(viewBadgesTypeSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewBadgesTypeFailed(message, meta))
    }
}

function* createBadge({ payload: data, meta }) {
    try {
        const response = yield call(createBadgeApi, data)
        yield put(createBadgeSuccess({response, data}, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(createBadgeFailed(message, meta))
    }
}

function* BadgesSaga() {
    yield takeEvery(VIEW_BADGES, viewBadges)
    yield takeEvery(VIEW_BADGES_TYPE, viewBadgesType)
    yield takeEvery(CREATE_BADGE, createBadge)
}

export default BadgesSaga
