import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk'

import { useFormik } from 'formik'

import { contactAdmin as onContactAdmin } from '../../store/auth/contact-admin/actions';

import ContactQueryReceivedModal from './ContactQueryReceivedModal';

const ContactUs = props => {

  const dispatch = useDispatch()

  const [ isReceivedModalVisible, setReceivedModalVisible ] = useState(false)

  const { get_success } = useSelector(state => ({
    get_success: fulfilled(state, "CONTACT_ADMIN")
  }))

  const initialValues = {
    name: "",
    email: "",
    message: ""
  }

  const onSubmit = values => {
    let data = {
      subject: values.name,
      message: values.message
    }
    dispatch(onContactAdmin(data))
  }

  const validate = values => {

    let errors = {}

    if(!values.name) {
        errors.name = "Name is required!"
    }

    if(!values.email) {
      errors.email = "Email is required"
    }
    else if(!/^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+.)+[a-z]{2,5}$/i.test(values.email)) {
        errors.email = "Enter a valid email address"
    }

    if(!values.message) {
      errors.message = "Message is required!"
  }

    return errors

  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  useEffect(() => {
    if(get_success) {
      dispatch(clean("CONTACT_ADMIN"))
      setReceivedModalVisible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  return (
    <div className='contact-wrapper'>
      <div className='contact-container d-lg-flex'>
        <div className='card contact-card lux rounded-xl'>
          <div className='card-body'>
            <h3 className='text-grey-870 liner mt-3'>Send us a message</h3>
            <div className="form-box">
            <form onSubmit={formik.handleSubmit}>
              <div className="input-box mb-4">
                <label htmlFor="name">Your name</label>
                <input className="form-control" type="text" name="name"  placeholder="Eg: John Paterson" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.name} />
                { formik.touched.name && <p className="mt-1 text-sm text-red-500">{formik.errors.name}</p> }
              </div>
              <div className="input-box mb-4">
                <label htmlFor="email">Email</label>
                <input className="form-control" type="email" name="email" placeholder="Eg: JohnPaterson@gmail.com" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                { formik.touched.email && <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p> }
              </div>
              <div className="input-box mb-4">
                <label htmlFor="message">Type your query</label>
                <textarea className="form-control" name="message" placeholder="Type message" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.message}></textarea>
                { formik.touched.message && <p className="mt-1 text-sm text-red-500">{formik.errors.message}</p> }
              </div>
              <button className='btn btn-primary'>Send a message</button>
            </form>
            </div>
          </div>
        </div>
      </div>
      <ContactQueryReceivedModal visible={isReceivedModalVisible}
            onClose = { () => {setReceivedModalVisible(!isReceivedModalVisible)} } />
    </div> 
  )
}

export default ContactUs