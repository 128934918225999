import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { fulfilled, clean } from 'redux-saga-thunk';

import home from "../../../../assets/images/home.png"

import { freePlanFeatures } from '../../../../constants/planFeatures';
import { proPlanFeatures } from '../../../../constants/planFeatures';

import { changeSubscription as onChangeSubscription } from '../../../../store/plans-and-prices/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const AccountMonthlyPlan = ({childrenCount}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()

    const [ isUpgrade, setUpgrade ] = useState(false)

    const { user, get_change_subscription_success } = useSelector(state => ({
        user: state.LoginReducer.user,
        get_change_subscription_success: fulfilled(state, "CHANGE_SUBSCRIPTION")
    })) 

    const getProAmount = () => {
        let amount = 14.99
        return (amount*childrenCount).toFixed(2)
    }

    const handleSignUp = (type) => {

        let query;
        let currentUrlParams = new URLSearchParams(location.search);

        if(currentUrlParams.get("upgrade")) {

            let data = {
                id: user.plans[0].id,
                plan_id: 3,
                plan_type: "monthly"
            }
            dispatch(onChangeSubscription(data))

        }
        else {
            if(type==="free") {
                query = `?sid=2&plan=${type}`
            }
            else {
                query = `?sid=2&plan=${type}&subscription=monthly`
            }
            navigate({ pathname: "/signup", search: query})
        }
        
    }

    useEffect(() => {
        getProAmount(childrenCount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childrenCount])

    useEffect(() => {
        
        let currentUrlParams = new URLSearchParams(location.search);
        if(currentUrlParams.get("upgrade")) {
            setUpgrade(true)
        }
        else {
            setUpgrade(false)
        }
    }, [location])

    useEffect(() => {
        if(get_change_subscription_success) {
            dispatch(clean("CHANGE_SUBSCRIPTION"))
            let query = `?upgrade=true&sid=3&plan=pro&subscription=monthly`
            navigate({ pathname: "/parent/billing-info", search: query})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_change_subscription_success])

  return (
    <div className='plan-type-wrapper'>
        <div className='row'>
            <div className='col-lg-4'>
                
                <div className={`plan-box ${isUpgrade ? "disabled" : ""}`}>
                    <div className='plan-amount text-center'>
                        <h6 className='box text-normal'>Free</h6>
                        <h4 className='mb-2 text-gray-950 text-base '>Home students</h4>
                        <div className='d-flex justify-content-center align-items-center'>
                            <FontAwesomeIcon className='text-white pound-icon' icon={['fas', 'fa-pound-sign']} />
                            <h2 className='text-white'>00.00</h2>
                        </div>
                        <h5 className='text-white fw-bold'>One month only</h5>
                    </div>
                    <div className='features text-center py-3'>
                        {freePlanFeatures.map((item, index) => {
                            return (
                                index < 2 && 
                                <>
                                    <div className='d-flex justify-content-center align-items-center my-4'>
                                        <FontAwesomeIcon className='tick-icon me-2' icon="fas fa-check-circle" />
                                        <p className='text-gray-950 fw-regular text-base'>{item}</p>
                                    </div>
                                </>
                            )
                        })}
                        <div className='btn btn-primary signup-btn mt-2 mb-3' onClick={() => handleSignUp('free')}>Sign up for free</div>
                    </div>
                </div>

            </div>
            <div className='col-lg-4'>
                <div className='plan-box'>
                    <div className='plan-amount text-center position-relative'>
                        <h6 className='box text-normal pro'>Pro</h6>
                        <h4 className='mb-2 text-gray-950 text-base '>Home students</h4>
                        <div className='d-flex justify-content-center align-items-center'>
                            <FontAwesomeIcon className='text-white pound-icon' icon={['fas', 'fa-pound-sign']} />
                            <h2 className='text-white'>{getProAmount()}</h2>
                        </div>
                        <h5 className='text-white fw-bold'>One month only</h5>
                    </div>
                    <div className='features text-center py-3'>
                        {proPlanFeatures.map((item, index) => {
                            return (
                                index < 2 && 
                                <>
                                    <div className='d-flex justify-content-center align-items-center my-4'>
                                        <FontAwesomeIcon className='tick-icon me-2' icon="fas fa-check-circle" />
                                        <p className='text-gray-950 fw-regular text-base'>{item}</p>
                                    </div>
                                </>
                            )
                        })}
                        <div className='btn btn-primary signup-btn mt-2 mb-3' onClick={() => handleSignUp('pro')}>Subscribe Now</div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='plan-box'>
                    <div className='plan-amount text-center'>
                        <h4 className='mb-2 text-gray-950 text-base '>School</h4>
                        <h3 className='fw-regular text-white text-center touch'>Get In touch for pricing detail.</h3>
                    </div>
                    <div className='features text-center py-3'>
                        <img className='mx-auto d-block mt-2 mb-3' src={home} alt="" />
                        <div className='btn btn-primary signup-btn mt-2 mb-3'>Go to enquiry</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default AccountMonthlyPlan