import React from 'react'
import { useSelector } from 'react-redux';

import MyStudent from './MyStudent';
import AssignedLesson from './AssignedLesson';

const Dashboard = props => {

  const { user } = useSelector(state => ({
    user: state.LoginReducer.user
  }))

  return (
    <div className='dashboard-wrapper bg-primary-100'>
      <div className='dashboard-container'>
        <h4 className='text-capitalize mb-3 fw-light'>Good Morning, <span className='fw-medium'>{user.first_name}</span></h4>
        <div className='content'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='feature-card bg-primary-300 h-100'>
                <h3 className='mb-3 fw-semibold'>Notices Comes Here</h3>
                <p className='text-sm'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt pariatur delectus necessitatibus! Quos, odio.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <AssignedLesson />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 col-xl-10'>
             <MyStudent />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard