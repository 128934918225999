import { takeEvery, put, call } from "redux-saga/effects"

import { CONTACT_ADMIN } from './actionTypes'
import { contactAdminSuccess, contactAdminFailed } from './actions'

import { contactAdminApi } from "../../../helpers/api/api"

function* contactAdmin({ payload: data , meta }) {
    try {
        const response = yield call(contactAdminApi,data)
       yield put(contactAdminSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(contactAdminFailed(message, meta))
    }
}

function* ContactAdminSaga() {
    yield takeEvery(CONTACT_ADMIN, contactAdmin)
}

export default ContactAdminSaga