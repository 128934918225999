import {
    VIEW_FAQ,
    VIEW_FAQ_SUCCESS,
    VIEW_FAQ_FAILED
} from './actionTypes'

export const viewFaq = (faq) => {
    return {
        type: VIEW_FAQ,
        payload: faq,
        meta:{
            thunk:true
        }
    }
}

export const viewFaqSuccess = (faq, {thunk}) => {
    return {
        type: VIEW_FAQ_SUCCESS,
        payload: faq,
        meta: {
            thunk
        }
    }
}

export const viewFaqFailed = (faq, {thunk}) => {
    return {
        type: VIEW_FAQ_FAILED,
        payload: faq,
        error: true,
        meta: {
            thunk
        }
    }
}