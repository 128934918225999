import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { fulfilled, rejected, clean } from "redux-saga-thunk"

import { useDispatch, useSelector } from 'react-redux';

import blockBg from "../../../../assets/images/signin-block-bg.png"
import OtpVerifyAccount from './OtpVerifyAccount';
import TermsPrivacyModal from './TermsPrivacyModal';

import { Alert } from "reactstrap"

import { useFormik } from 'formik';

import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import { signupUser as onSignupUser } from '../../../../store/auth/signup/actions'
import { buyPlan as onBuyPlan } from '../../../../store/plans-and-prices/actions'
import { viewProfile as onViewProfile } from "../../../../store/auth/login/actions";

import { sessionData } from '../../../../constants/sessiondata';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const RegisterAccount = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  const isSignup = location.pathname.includes("signup")

  const [ showPassword, setShowPassword ] = useState(false)
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)

  const [ isTermsVisible, SetTermsVisible ] = useState(false)

  const { signup_user, error, get_success, get_error, get_plan_success, get_plan_error } = useSelector(state => ({
    signup_user: state.SignupReducer.user,
    error: state.SignupReducer.error,
    get_success: fulfilled(state, "SIGNUP_USER"),
    get_error: rejected(state, "SIGNUP_USER"),
    get_plan_success: fulfilled(state, "BUY_PLAN"),
    get_plan_error: rejected(state, "BUY_PLAN")
  }))


  const initialValues = {
    full_name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    terms: false
  }

  const onSubmit = () => {
    let userData = formik.values;
    delete userData['terms']
    delete userData['confirm_password']
    dispatch(onSignupUser(userData))
  }

  const validate = (values) => {
    
    let errors = {}

    if(!values.full_name) {
        errors.full_name = "Full Name is required!"
    }

    if(!values.email) {
        errors.email = "Email is required"
    }
    else if(!/^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+.)+[a-z]{2,5}$/i.test(values.email)) {
        errors.email = "Enter a valid email address"
    }

    if(!values.phone) {
        errors.phone = "Phone Number is required!"
    }

    if(!values.password) {
        errors.password = "Password is required!"
    }
    else if(values.password.length < 6) {
        errors.password = "Password should contain minimum 6 characters!"
    }

    if(!values.confirm_password) {
        errors.confirm_password = "Password is required!"
    }
    else if(values.password !== values.confirm_password) {
        errors.confirm_password = "Password did not match!"
    }

    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  const handleTerms = () => {
    formik.setFieldValue("terms", !formik.values.terms)
  }

  const handlePhoneChange = (value) => {
    formik.setFieldValue("phone", value)
  }

  const handleOTP = () => {
    let currentUrlParams = new URLSearchParams(location.search);
    currentUrlParams.set('sid', 3)
    if(currentUrlParams.get('plan') === "free") {
        navigate(
            {
                pathname: "/parent/add-student",
                search: currentUrlParams.toString()
            },
            { replace: true }
        )
    }
    else {
        navigate(
            {
                pathname: "/parent/billing-info",
                search: currentUrlParams.toString()
            },
            { replace: true }
        )
    }
  }
    
  const showTerms = (e) => {
    e.preventDefault()
    SetTermsVisible(true)
  }

  useEffect(() => {
    dispatch(clean("SIGNUP_USER"))
    if(get_success) {
        let currentUrlParams = new URLSearchParams(location.search);
        let plan_id = (currentUrlParams.get('plan') === "free") ? 2 : 3;
        let number_of_student = (currentUrlParams.get('plan') === "free") ? 1 : 2;

        let data = {
            plan_id,
            plan_type: (currentUrlParams.get('plan') === "free") ? "monthly" : currentUrlParams.get('subscription'),
            parent_id: signup_user?.id,
            number_of_student
        }
        dispatch(onBuyPlan(data))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])


  useEffect(() => {
    dispatch(clean("BUY_PLAN"))
    if(get_plan_success) {
        dispatch(onViewProfile(parseInt(sessionData.getParentId())))
        let currentUrlParams = new URLSearchParams(location.search);
        navigate(
            {
                pathname: "/email-verify",
                search: currentUrlParams.toString()
            },
            { replace: true }
        )
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_plan_success])
  
  return (
    <div className="content-container register-container d-flex w-100">
        <div className="left d-none d-lg-block">
            <img className="img-fluid block-img" src={blockBg} alt="" />
        </div>
        <div className="right">
            
            <div className='card-box'>

                { isSignup ? (
                    <div className='signup-card card lux rounded-lux'>
                    <div className='card-body'>
                        <div className="form-container">
                            <h2 className="text-gray-980 fw-semibold ls--2 mb-4">Welcome to Skillup</h2>
                            <h5 className="text-gray-980 fw-regular mb-2">Create an account</h5>
                            <p className="text-gray-980 text-xs op-50">Enter your information below create an account</p>
                            <div className='form-box'>
                                <form onSubmit={formik.handleSubmit}>
                                {(error && get_error) && ( <Alert color="danger">{error}</Alert>)}
                                    <div className='input-box auth-box'>
                                        <label htmlFor="full_name">Full Name</label>
                                        <div className="input-group">
                                            <input className='form-control' type="text" id="full_name" name="full_name" placeholder='Eg: John Paterson' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.full_name} />
                                            { (formik.touched.full_name && !formik.errors.full_name) &&  
                                                <span className='input-group-text'>
                                                    <FontAwesomeIcon className='text-primary-950 tick-icon' icon="fa-solid fa-check" />
                                                </span>
                                            }
                                        </div>
                                        { formik.touched.full_name && <p className="mt-1 text-sm text-red-500">{formik.errors.full_name}</p> }
                                    </div>
                                    <div className='input-box auth-box'>
                                        <label htmlFor="email">Email Address</label>
                                        <div className="input-group">
                                            <input className='form-control' type="email" id="email" name="email" placeholder='student@school.com' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                            { (formik.touched.email && !formik.errors.email) &&  
                                                <span className='input-group-text'>
                                                    <FontAwesomeIcon className='text-primary-950 tick-icon' icon="fa-solid fa-check" />
                                                </span>
                                            }
                                        </div>
                                        { formik.touched.email && <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p> }
                                    </div>
                                    <div className='input-box auth-box phone-box'>
                                        <label htmlFor="PhoneNumber">Phone Number</label>
                                        <ReactPhoneInput 
                                        country={"gb"} onlyCountries={["lk", "gb"]} onChange={handlePhoneChange} />
                                        { formik.touched.phone && <p className="mt-1 text-sm text-red-500">{formik.errors.phone}</p> }
                                    </div>
                                    <div className='input-box auth-box'>
                                        <label htmlFor="password">Password</label>
                                        <div className="input-group">
                                            <input className='form-control' type={ showPassword ? 'text' : 'password' } id="password" name="password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.password} />
                                            { (formik.touched.password && !formik.errors.password) &&  
                                                <span className='input-group-text'>
                                                    <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
                                                    <FontAwesomeIcon className='pointer text-sm text-primary-950 ms-3' icon={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowPassword(!showPassword)}} />
                                                </span>
                                            }
                                        </div>
                                        { formik.touched.password && <p className="mt-1 text-sm text-red-500">{formik.errors.password}</p> }
                                    </div>
                                    <div className='input-box auth-box'>
                                        <label htmlFor="confirm_password">Confirm Password</label>
                                        <div className="input-group">
                                            <input className='form-control' type={ showConfirmPassword ? 'text' : 'password' } id="confirm_password" name="confirm_password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.confirm_password} />
                                            { (formik.touched.confirm_password && !formik.errors.confirm_password) &&  
                                                <span className='input-group-text'>
                                                    <FontAwesomeIcon className='text-primary-950 tick-icon' icon="fa-solid fa-check" />
                                                    <FontAwesomeIcon className='pointer text-sm text-primary-950 ms-3' icon={`fa-solid ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowConfirmPassword(!showConfirmPassword)}} />
                                                </span>
                                            }
                                        </div>
                                        { formik.touched.confirm_password && <p className="mt-1 text-sm text-red-500">{formik.errors.confirm_password}</p> }
                                    </div>
                                    <div className='input-box auth-box'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="termsCheck" id="termsCheck" checked={formik.values.terms} onChange={handleTerms}  />
                                            <label className="form-check-label text-gray-960 text-sm" htmlFor="termsCheck">By checking this, you will agree to our <Link className="text-primary-950 fw-medium" to="" onClick={(e) => showTerms(e)} >Terms and privacy policy</Link></label>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button type="submit" className="btn btn-special w-100 text-uppercase ls--15">Sign Up</button>
                                    </div>
                                    <div className="mt-3 text-center">
                                        <span className="ft-head fw-medium text-sm ls--25">Already have an account?</span> <Link to="/sign-in" className="text-blue-500 fw-medium ft-head">Sign In</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
                    <OtpVerifyAccount onClose={handleOTP} />
                ) } 
            </div>
        </div>
        <TermsPrivacyModal visible={isTermsVisible} 
            onClose = { () => {SetTermsVisible(!isTermsVisible)} }/>
    </div>
  )
}


export default RegisterAccount