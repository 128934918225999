import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk';

import { useFormik } from 'formik'
import DatePicker from "react-datepicker";
import moment from 'moment';

import { addCard as onAddCard, updateCard as onUpdateCard } from '../../store/card/actions';
import { paymentCards } from '../../constants/variousdata'


import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const AddCard = ({setup, card, onSubmitData ,onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const [ expiryDate, setExpiryDate] = useState(Date.parse(null))

  const { user, get_card_success, update_card_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    get_card_success: fulfilled(state, "ADD_CARD"),
    update_card_success: fulfilled(state, "UPDATE_CARD")
  }))

  const onSubmit = values => {
    

    let data = {
        user_id: user.id,
        holder_name: values.holder_name,
        card_number: values.card_number,
        expiry_date: values.expiry_date,
        card_type: values.card_type,
        cvc: values.cvc,
        default: values.default
    }
    if(setup) {
      dispatch(onAddCard(data))
    }
    else {
      data.id = card.id
      dispatch(onUpdateCard(data))
    }
  }

  const initialValues = {
    holder_name: "",
    card_number: "",
    expiry_date: "",
    card_type: "",
    cvc: "",
    default: "0"
  }
  
  const validate = values => {

    let errors = {}

    if(!values.card_type) {
        errors.card_type = "Card type is required!"
    }

    if(!values.holder_name) {
        errors.holder_name = "Card holder name is required!"
    }

    if(!values.card_number) {
        errors.card_number = "Card number is required!"
    }
    else if(values.card_number.length < 16) {
        errors.card_number = "Card number should contain 16 digits!"
    }

    if(!values.expiry_date) {
        errors.expiry_date = "Expiry date is required!"
    }
    else if(isNaN(Date.parse(expiryDate))) {
        errors.expiry_date = "Expiry date is required!"
    }

    if(!values.cvc) {
        errors.cvc = "CVC is required!"
    }
    else if(values.cvc.length < 3) {
        errors.cvc = "Password should contain 3 or 4 digits!"
    }

    return errors

}

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  const handleCardType = (name) => {
    formik.setFieldValue("card_type", name)
  }
  
  const handleExpiryDate = (date) => {
    setExpiryDate(date)
    setTimeout(() => {
        formik.setFieldValue("expiry_date", moment(date).format('MM/YYYY'))
    }, 100)
  }

  const handleDefaultCard = () => {
    if(formik.values.default === "0")
        formik.setFieldValue("default", "1")
    else
        formik.setFieldValue("default", "0")
  }

  useEffect(() => {
    dispatch(clean("ADD_CARD"))
    if(get_card_success) {
      
      if(setup) {
        let currentUrlParams = new URLSearchParams(location.search);
        currentUrlParams.set('sid', 4)
        navigate( { pathname: "/parent/payment-success", search: currentUrlParams.toString() }, { replace: true })
      }
      else {
        onSubmitData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [get_card_success])


useEffect(() => {
  dispatch(clean("UPDATE_CARD"))
  if(update_card_success) {
    onSubmitData()
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [update_card_success])

  return (
    <div className='add-payment-card-wrapper'>
        <form name="addPaymentCardForm" onSubmit={formik.handleSubmit}>
          
          <div className='card-container'>
            <label htmlFor="holder_name">Add debit/credit card</label>
            <div className='payment-cards-container mt-2 mb-3'>

                <div className='d-flex align-items-center'>
                    { paymentCards.map((item, index) => {
                        return (
                            <div className='payment-box mx-1' key={index} onClick={() => {handleCardType(item.name)}}>
                                <img className='card' src={item.image} alt="" />
                                <div className={`checked ${formik.values.card_type === item.name ? "d-inline-block" : "d-none"}`}>
                                    <img src={item.checked} alt="" />
                                </div>
                            </div>
                        )
                    })}
                </div>
                { formik.errors.card_type &&  <div className='mt-1'>
                { formik.touched.card_type && <p className="mt-1 text-sm text-red-500">{formik.errors.card_type}</p> }
                </div> }
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
                <div className='input-box mb-4'>
                    <label htmlFor="holder_name">Card holder name<span className='text-custom-red'>*</span></label>
                    <input type="text" className='form-control' name="holder_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.holder_name} />
                    { formik.touched.holder_name && <p className="mt-1 text-sm text-red-500">{formik.errors.holder_name}</p> }
                </div>
            </div>
            <div className='col-md-6'>
                <div className='input-box mb-4'>
                    <label htmlFor="card_number">Card number<span className='text-custom-red'>*</span></label>
                    <div className="input-group">
                        <input type="nunber" className='form-control' name="card_number" pattern='[0-9]*\.?[0-9]*' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.card_number} maxLength="16" />
                        <span className='input-group-text'>
                            <FontAwesomeIcon className='text-primary-950 tick-icon' icon="fa-solid fa-lock" />
                        </span>
                      </div>
                    { formik.touched.card_number && <p className="mt-1 text-sm text-red-500">{formik.errors.card_number}</p> }
                </div>
            </div>

            <div className='col-md-6'>
                <div className='input-box readonly-box expiry-date-box mb-4'>
                    <label htmlFor="expiry_date">Expiry date<span className='text-custom-red'>*</span></label>
                    <div className="input-group">
                        <span className="input-group-text pointer">
                            <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-calendar-days" />
                        </span>
                        <DatePicker 
                            className='form-control pointer' 
                            placeholderText={'DD/MM/YYYY'} 
                            id="example-datepicker" 
                            showMonthYearPicker
                            selected={expiryDate}
                            dateFormat="MM/yyyy" 
                            onChange={(date) => handleExpiryDate(date)}
                            onBlur={formik.handleChange}
                            autoComplete="off" 
                        />
                    </div>
                    { formik.touched.expiry_date && <p className="mt-1 text-sm text-red-500">{formik.errors.expiry_date}</p> }
                </div>
            </div>
                        
            <div className='col-md-6'>
                <div className='input-box mb-4'>
                    <label htmlFor="cvc">CVC number<span className='text-custom-red'>*</span></label>
                    <input type="number" className='form-control' name="cvc" pattern='[0-9]' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.cvc} maxLength="4" />
                    { formik.touched.cvc && <p className="mt-1 text-sm text-red-500">{formik.errors.cvc}</p> }
                </div>
            </div>
          </div>
          <div className='mt-3 save-card d-flex align-items-center'>
              <div className='check-box'>
                  <input type="checkbox" checked={formik.values.default !== "0" ? true : false} onChange={handleDefaultCard} />
                  <span className='input-check'></span>
              </div>
              <p className='ms-3 text-gray-800 ft-default text-base'>Save as default payment card</p>
          </div>
          <div className='mt-5'>
                
            { onClose ? (
                <>
                    <button type="submit" className='btn btn-primary submit-btn'>Save</button>
                    <div className='ms-4 btn btn-secondary submit-btn' onClick={onClose}>Back</div>
                </>
            ) : (
                <button type="submit" className='btn btn-primary submit-btn'>Continue</button>
            )}
          </div>

        </form>

    </div>
  )
}

export default AddCard