export const VIEW_BILLING_INFO = "VIEW_BILLING_INFO"
export const VIEW_BILLING_INFO_SUCCESS = "VIEW_BILLING_INFO_SUCCESS"
export const VIEW_BILLING_INFO_FAILED = "VIEW_BILLING_INFO_FAILED"

export const ADD_BILLING_INFO = "ADD_BILLING_INFO"
export const ADD_BILLING_INFO_SUCCESS = "ADD_BILLING_INFO_SUCCESS"
export const ADD_BILLING_INFO_FAILED = "ADD_BILLING_INFO_FAILED"

export const UPDATE_BILLING_INFO = "UPDATE_BILLING_INFO"
export const UPDATE_BILLING_INFO_SUCCESS = "UPDATE_BILLING_INFO_SUCCESS"
export const UPDATE_BILLING_INFO_FAILED = "UPDATE_BILLING_INFO_FAILED"