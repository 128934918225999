export const genderData = [
    { id: "1", name: "Male" },
    { id: "2", name: "Female" }
]

export const autoRenewalOptionsData = [
    { id: 1, name: "I don’t like automatic billing" },
    { id: 2, name: "I want to cancel my plan" },
    { id: 3, name: "I don’t understand how auto renewal works" },
    { id: 4, name: "My needs have changed" },
    { id: 5, name: "Other" }
]

export const paymentCards = [
    { id: 1, name: "master", image:"/assets/images/payment-card-mastercard-img.png", checked: "/assets/images/payment-card-default-check.png" },
    { id: 2, name: "visa", image:"/assets/images/payment-card-visa-img.png", checked: "/assets/images/payment-card-default-check.png" },
    { id: 3, name: "stripe", image:"/assets/images/payment-card-stripe-img.png", checked: "/assets/images/payment-card-default-check.png" }
]

export const rewardPointsData = [
    { id: "1", name: "30" },
    { id: "2", name: "50" },
    { id: "3", name: "75" },
    { id: "4", name: "100" }
]