import { call, put, takeEvery } from "redux-saga/effects"

import { VIEW_BILLING_INFO, ADD_BILLING_INFO, UPDATE_BILLING_INFO } from "./actionTypes"
import { viewBillingSuccess, viewBillingFailed , updateBillingInfoSuccess, updateillingInfoFailed , addBillingInfoSuccess, addBillingInfoFailed } from "./actions"

import { viewBillingInfoApi ,addBillingInfoApi, updateBillingInfoApi } from '../../helpers/api/api'

function* viewBillingInfo({ payload: data, meta }) {
    try {
        const response = yield call(viewBillingInfoApi,data)
        yield put(viewBillingSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewBillingFailed(message, meta))
    }
    
}

function* addBillingInfo({ payload: data, meta }) {
    try {
        const response = yield call(addBillingInfoApi,data)
        yield put(addBillingInfoSuccess({response, data}, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(addBillingInfoFailed(message, meta))
    }
    
}

function* updateBillingInfo({ payload: data, meta }) {
    try {
        const response = yield call(updateBillingInfoApi,data)
        yield put(updateBillingInfoSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateillingInfoFailed(message, meta))
    }
    
}

function* BillingSaga() {
    yield takeEvery(ADD_BILLING_INFO, addBillingInfo)
    yield takeEvery(UPDATE_BILLING_INFO, updateBillingInfo)
    yield takeEvery(VIEW_BILLING_INFO, viewBillingInfo)
}
  
  export default BillingSaga