import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk'

import { viewSubscriptions as onViewSubscriptions } from "../../../store/plans-and-prices/actions"

import moment from 'moment';
import _ from 'underscore'

import { useFormik } from 'formik'

import { showToastMessage } from '../../../helpers/toaster';

import { contactAdmin as onContactAdmin } from '../../../store/auth/contact-admin/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const PurchaseHistory = props => {

  const dispatch = useDispatch()

  const { user, subscriptions, get_success, get_contact_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    subscriptions: state.PlansAndPricesReducer.subscriptions,
    get_success: fulfilled(state, "VIEW_MY_SUBSCRIPTIONS"),
    get_contact_success: fulfilled(state, "CONTACT_ADMIN")
  }))

  const [ subsArray, setSubsArray ] = useState([])
  const [ isReceiptVisible, setReceiptVisible ] = useState(false)
  const [ isContactAdminVisible, setContactAdminVisible ] = useState(false)
  const [ selectedInvoice, setSelectedInvoice ] = useState(null)

  const initialValues = {
    message: ""
  }

  const onSubmit = values => {
    let data = {
      subject: "Invoice",
      message: values.message
    }
    dispatch(onContactAdmin(data))
  }

  const validate = values => {

    let errors = {}

    if(!values.message) {
        errors.message = "Message is required!"
    }

    return errors

  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  const getStatus = (status) => {
    if(status === "succeeded") {
      return "active"
    }
    else if(status === "inactive") {
      return "inactive"
    }
    else if(status === "cancel") {
      return "cancel"
    }
    else {
      return ""
    }
  }

  const handleViewReceipt = (status, data) => {
    setReceiptVisible(status)
    setContactAdminVisible(false)
    console.log(data)
    setSelectedInvoice(data)
  }

  const handleContactAdmin = (status) => {
    setContactAdminVisible(status)
    setReceiptVisible(!status)
  }

  const getCardType = (user) => {
    if(user?.card_detail) {
      return user.card_detail.card_type
    }
    else {
      return ""
    }
  }

  const handleDownloadInvoice = () => {
    window.open(selectedInvoice.invoice_pdf)
  }

  useEffect(() => {
    if(!_.isEmpty(user)) {
      let data = { user_id: user.id }
      dispatch(onViewSubscriptions(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    setSubsArray([subscriptions])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  useEffect(() => {
    if(get_contact_success) {
      dispatch(clean("CONTACT_ADMIN"))
      showToastMessage('success',"Message Sent")
      setReceiptVisible(false)
      setContactAdminVisible(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_contact_success])

  useEffect(() => {
    console.log(selectedInvoice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoice])


  return (
    <div className='purchase-history-wrapper'>
      <div className='row'>
        <div className='col-sm-12'>

          { !isReceiptVisible && !isContactAdminVisible && ( 
            <div className='card table-card'>
              <div className='card-body p-0'>
                <table className='table mb-0'>
                  <thead>
                    <tr>
                      <th>My subscription</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Payment Type</th>
                      <th>Renew Date</th>
                      <th>Status</th>
                      <th>Receipt</th>
                    </tr>
                  </thead>
                  <tbody>
                    { get_success && subsArray.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.plan_id === "3" ? "Pro" : "Free"}</td>
                          <td>{moment(item?.start_date).format("Do MMMM YYYY")}</td>
                          <td>{item?.price}</td>
                          <td className='text-capitalize'>{getCardType(item?.user)}</td>
                          <td>{moment(item?.end_date).format("Do MMMM YYYY")}</td>
                          <td className={`text-capitalize text-status-${getStatus(item?.status)}`}>{getStatus(item?.status)}</td>
                          <td className='pointer' onClick={() => {handleViewReceipt(true, item.invoices)}}>View Receipt</td>
                        </tr>
                      )
                    }) }
                  </tbody>
                </table>
              </div>
            </div>
          )}

          { isReceiptVisible && (
            <div className='card invoice-card'>
              <div className='card-body'>
                <div className='d-flex justify-content-end align-items-center mb-3'>
                  <FontAwesomeIcon className='text-primary-950 text-h4 pointer' icon="fas fa-download" onClick={handleDownloadInvoice} />
                  <FontAwesomeIcon className='text-gray-280 text-h4 ms-4 pointer' icon="fas fa-times-circle" onClick={() => {handleViewReceipt(false, null)}} />
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='img-box'>
                      <img src={user.image} alt="" />
                    </div>
                    <div className='desp'>
                      <h6 className='ft-default text-gray-900 mb-1'>Address</h6>
                      <p className='text-base text-gray-700'>
                        {user.address}
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='invoice-content h-100 d-flex flex-column'>
                      <h4 className='ft-default text-gray-900 text-center mb-4'>Invoice</h4>
                      <div className='mx-auto'>
                        <h6 className='text-gray-950 mb-2 fw-regular'><span className='text-base text-gray-700'>Invoice:</span> {selectedInvoice.id}</h6>
                        <h6 className='text-gray-950 fw-regular'><span className='text-base text-gray-700'>Date:</span> {moment.unix(selectedInvoice.created).format("DD/MM/YYYY")}</h6>
                      </div>
                      <div className='mt-5'>
                        <h4 className='ft-default text-gray-900 text-center mb-4'>Summary</h4>
                        <div className='amount-list'>
        
                          <div className='list-item py-2 w-100'>
                            <div className='d-flex px-4'>
                              <div className='text-h6 text-gray-700 w-50'>Listed Amount</div>
                              <div className='text-h6 text-gray-920'>{selectedInvoice.subtotal}</div>
                            </div>
                          </div>
                          <div className='list-item py-2 w-100'>
                            <div className='d-flex px-4'>
                              <div className='text-h6 text-gray-700 w-50'>VAT (10%)</div>
                              <div className='text-h6 text-gray-920'>{ selectedInvoice.tax}</div>
                            </div>
                          </div>
                          <div className='list-item py-2 w-100'>
                            <div className='d-flex px-4'>
                              <div className='text-h6 text-gray-700 w-50 text-primary-800'>Total</div>
                              <div className='text-h6 text-gray-920 text-primary-800'>{selectedInvoice.total}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h4 className='text-center text-primary-800 ft-default fw-regular text-decoration-underline pointer mt-5 mb-3' onClick={() => {handleContactAdmin(true)}}>Contact admin</h4>
                      </div>
                    </div> 
                  <div className='col-md-3'>
                    <div className='desp text-md-end mt-5'>
                      <h6 className='ft-default text-gray-900 mb-1'>Receipent Details</h6>
                      <p className='text-base text-gray-700'>{user.full_name}</p>
                      <p className='text-base text-gray-700'>{user.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          { isContactAdminVisible && (
            <div className='card contact-card'>
              <div className='card-body'>
                <div className='d-flex justify-content-end align-items-center mb-3'>
                  <FontAwesomeIcon className='text-gray-280 text-h4 ms-4 pointer' icon="fas fa-times-circle" onClick={() => {handleContactAdmin(false)}} />
                </div>
                <h4 className='ft-default text-gray-900 text-center mb-4'>Contact Admin</h4>
                <div className='receipent-content d-md-flex justify-content-between mb-2'>
                  <div className='mb-3'>
                    <h6 className='ft-default fw-regular mb-2 text-gray-700'>Name:</h6>
                    <h6 className='ft-default fw-regular mb-2 text-gray-900'>{user.full_name}</h6>
                  </div>
                  <div className='mb-3'>
                    <h6 className='ft-default fw-regular mb-2 text-gray-700'>Reference No:</h6>
                    <h6 className='ft-default fw-regular mb-2 text-gray-900'>{selectedInvoice.id}</h6>
                  </div>
                  <div className='mb-3'>
                    <h6 className='ft-default fw-regular mb-2 text-gray-700'>Date:</h6>
                    <h6 className='ft-default fw-regular mb-2 text-gray-900'>{moment.unix(selectedInvoice.created).format("DD/MM/YYYY")}</h6>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <textarea name="message" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.message}></textarea>
                  { formik.touched.message && formik.errors.message && <p className="mt-1 text-sm text-red-500">{formik.errors.message}</p> }
                  <div className='text-center'>
                    <button className='btn btn-primary submit-btn ft-default'>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          )}
                  
        </div>
      </div>
    </div>
  )
}


export default PurchaseHistory