import { call, put, takeEvery } from "redux-saga/effects"

import { GET_EXAM_BOARDS } from './actionTypes'

import {
    getExamBoardsSuccess,
    getExamBoardsFailed
} from './actions'

import { getExamboardsApi } from "../../../helpers/api/api"

function* onGetGrades({ meta }) {
    try {
      const response = yield call(getExamboardsApi)
      yield put(getExamBoardsSuccess(response, meta))
    } catch (error) {
      yield put(getExamBoardsFailed(error, meta))
    }
  }

function* ExamBoardsSaga() {
    yield takeEvery(GET_EXAM_BOARDS, onGetGrades)
}

export default ExamBoardsSaga