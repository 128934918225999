import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fulfilled } from "redux-saga-thunk"

import NotificationSetting from './NotificationSetting'

import { viewNotifications as onViewNotifications, updateNotification as onUpdateNotification } from '../../store/notifications/actions'

const Notifications = props => {

  const dispatch = useDispatch()

  const { notifications, get_success, get_update_success  } = useSelector(state => ({
    notifications: state.NotificationsReducer.notifications,
    get_success: fulfilled(state, "VIEW_NOTIFICATIONS"),
    get_update_success: fulfilled(state, "UPDATE_NOTIFICATION")
  }))

  const handleUnreadNotifications = (item) => {
    if(item.read !== 'true') {
      let data = {
        notification_id: item.id,
        read: "true"
      }
      dispatch(onUpdateNotification(data))
    }
  }

  useEffect(() => {
    let data = {
      target: 'parent'
    }
    dispatch(onViewNotifications(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(get_update_success) {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_update_success])

  return (
    <div className='notification-wrapper'>
        <h5 className='title'>Notification</h5>
        <div className='content-box'>
            <div className='row'>
              <div className='col-md-12 col-lg-8'>
                <div className='card h-100 shadow'>
                  <div className='card-body p-0'>
                  <ul className='list-group'>

                    { get_success && notifications && notifications.map((item, index) => (
                      <li key={index} className='list-group-item' onClick={() => (handleUnreadNotifications(item))}>
                        <div className='row'>
                          <div className='col-md-8 col-lg-9 d-flex'>
                          <div className={`name flex-shrink-0`}>
                            <img className='img-full' src={item.image} alt="" />
                          </div>
                            <div className='content ms-3'>
                              <p className={`text-gray-920 text-base ${item.read === 'true' ? "" : "fw-semibold"}`}>{item.title}</p>
                              <p className={`mt-2 text-normal text-gray-920 ${item.read === 'true' ? "" : "fw-medium"}`}>{item.description}</p>
                            </div>
                          </div>
                          <div className='col-md-4 col-lg-3'>
                            <p className={`ms-auto time mt-2 text-sm text-gray-700 text-end ${item.read === 'true' ? "" : "fw-semibold"}`}>{item.created_at}</p>
                          </div>
                        </div>
                      </li>
                    ))}

                  </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-lg-4'>
                <NotificationSetting />
              </div>
            </div>
        </div>
    </div>
  )
}

export default Notifications