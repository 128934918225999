import React,  { useEffect } from "react"
import { Outlet  } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from './Sidebar/Sidebar'
import Header from './Header/Header'

import { viewProfile as onViewProfile } from "../../store/auth/login/actions";
import { sessionData } from "../../constants/sessiondata";

const Layout = ( { isSetup, isSidebar } ) => {
  
  const dispatch = useDispatch()

  const { user } = useSelector(state => ({
    user: state.LoginReducer.user
  }))

  //on page refresh fetch the user data fron api
  useEffect(() => {
    if(sessionData.getAuthUser() != null)
    {
      let data = {
        id: parseInt(sessionData.getParentId())
      }
      dispatch(onViewProfile(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
   <>
    <div className={`layout-wrapper ${ isSidebar ? 'bar' : ''}`}>
        <Header isToggleAvailable = {isSidebar} isSetup={isSetup} />
        <div className="main-container d-flex">
          { isSidebar && <Sidebar/> }
          <div className={`content-block ${user?.user_plan !== null ? "bg-primary-100" : "transparent"}`}>
            <Outlet />
          </div>
        </div>
    </div>    
   </>
  )
}

export default Layout