import {
  SIGNUP_USER, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAILED
} from './actionTypes'

import { sessionData } from '../../../constants/sessiondata'

const INIT_STATE = {
  error: null,
  message: null,
  loading: false,
  user: null,
}

const SignupReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case SIGNUP_USER: {
        return {
          ...state,
          loading: true,
          error: "",
        }
      }
      case SIGNUP_USER_SUCCESS: {
        const { user } = action.payload
        sessionData.setParentId(user.id)
        return {
          ...state,
          loading: false,
          user,
          error: "",
        }
      }
      case SIGNUP_USER_FAILED: {
        return {
          ...state,
          loading: false,
          user: null,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default SignupReducer;