import createSagaMiddleware from "redux-saga"
import { configureStore } from '@reduxjs/toolkit'
//import { applyMiddleware, compose } from "redux"
import { middleware as thunkMiddleware } from 'redux-saga-thunk'


import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({ 
    reducer: rootReducer,
    middleware: [thunkMiddleware, sagaMiddleware] 
})
sagaMiddleware.run(rootSaga)

export default store
