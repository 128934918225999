
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fulfilled } from 'redux-saga-thunk';


const PaymentSummary = props => {

    const { user, get_success } = useSelector(state => ({
        user: state.LoginReducer.user,
        get_success: fulfilled(state, "VIEW_USER_PROFILE")
    }))
    
    const [ planDetails, setPlanDetails ] = useState(null)

    useEffect(() => {
        if(get_success) {
            setPlanDetails(user.plans[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_success])

  return (
    <div className='payment-summary-wrapper'>
        { planDetails && 
            <form name="paymentSummaryForm">
                <div className='d-flex'>
                    <h6 className='ft-default fw-regular text-h6 text-gray-950'>Package</h6>
                    <h6 className='ms-auto fw-regular'>{planDetails.name}</h6>
                </div>
                <div className='coupon-box'>
                    <div className='input-box mt-4 mb-0'>
                        <input type="text" className='form-control border border-text-gray-180 coupon-input' placeholder='Enter coupon code' name="coupon_code" />
                    </div>
                    <div className='btn coupon-btn fw-regular text-h6 text-gray-950'>Apply coupon</div>
                </div>
                <div className='sub-total border-top border-text-gray-700 py-4 mt-3'>
                    <div className='d-flex mb-4'>
                        <h6 className='ft-default fw-regular text-h6 text-gray-950'>Sub Total</h6>
                        <h6 className='ms-auto fw-regular'>£ {planDetails.monthly_price}</h6>
                    </div>
                    <div className='d-flex'>
                        <h6 className='ft-default fw-regular text-h6 text-gray-950'>Coupon discounts</h6>
                        <h6 className='ms-auto fw-regular'>£ 00.00</h6>
                    </div>
                </div>
                <div className='sub-total border-top border-text-gray-700 py-4 mt-2'>
                    <div className='d-flex'>
                        <h6 className='ft-default fw-regular text-h6 text-gray-950'>Total</h6>
                        <h6 className='ms-auto fw-regular'>£ {planDetails.yearly_price}</h6>
                    </div>
                </div>
            </form>
        }
    </div>
  )
}

export default PaymentSummary