import {
    VIEW_REWARD_SUCCESS,
    VIEW_REWARD_FAILED,
    VIEW_REWARD_CATEGORY_SUCCESS,
    VIEW_REWARD_CATEGORY_FAILED,
    CREATE_REWARD_SUCCESS,
    CREATE_REWARD_FAILED,
    UPDATE_REWARD_SUCCESS,
    UPDATE_REWARD_FAILED,
    DELETE_REWARD_SUCCESS,
    DELETE_REWARD_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    reward: null,
    reward_category: null
}


const RewardReducer = (state = INIT_STATE, action) => {

    switch (action.type) {

      case VIEW_REWARD_SUCCESS: {
        return {
          ...state,
          loading: false,
          reward: action.payload,
          error: "",
        }
      }
      case VIEW_REWARD_FAILED: {
        return {
          ...state,
          loading: false,
          reward: null,
          error: action.payload
        }
      }
      case VIEW_REWARD_CATEGORY_SUCCESS: {
        return {
          ...state,
          loading: false,
          reward_category: action.payload,
          error: "",
        }
      }
      case VIEW_REWARD_CATEGORY_FAILED: {
        return {
          ...state,
          loading: false,
          reward_category: null,
          error: action.payload,
        }
      }
      case CREATE_REWARD_SUCCESS: {
        const data = action.payload.data
        return {
          ...state,
          loading: false,
          reward: [...state.reward, data],
          error: "",
        }
      }
      case CREATE_REWARD_FAILED: {
        return {
          ...state,
          loading: false,
          reward: null,
          error: action.payload
        }
      }
      case UPDATE_REWARD_SUCCESS: {
        const data = action.payload.data
        const _index = state.reward.findIndex(item => item.id === data.id)
        return {
          ...state,
          reward: [
            ...state.reward.map((item, index) => {
                if (index !== _index) {
                  return item
                }
                return {
                  ...item,
                  ...data
                }
            })
          ]
        }
      }
      case UPDATE_REWARD_FAILED: {
        return {
          ...state,
          loading: false,
          reward: null,
          error: action.payload,
        }
      }
      case DELETE_REWARD_SUCCESS:{
        const id = action.payload.data.id
        return {
          ...state,
          reward: state.reward.filter(
            item => item.id.toString() !== id.toString()
          ),
        }
      }
      case DELETE_REWARD_FAILED: {
        return {
          ...state,
          error: action.payload
        }
      }
      default:
        return state
    }

}

export default RewardReducer;