import {
    VIEW_BADGES_SUCCESS,
    VIEW_BADGES_FAILED,
    VIEW_BADGES_TYPE_SUCCESS,
    VIEW_BADGES_TYPE_FAILED,
    CREATE_BADGE_SUCCESS,
    CREATE_BADGE_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    badges: null,
    badges_type: null
}

const BadgesReducer = (state = INIT_STATE, action) => {

    switch (action.type) {

      case VIEW_BADGES_SUCCESS: {
        return {
          ...state,
          loading: false,
          badges: action.payload,
          error: "",
        }
      }
      case VIEW_BADGES_FAILED: {
        return {
          ...state,
          loading: false,
          badges: null,
          error: action.payload
        }
      }
      case VIEW_BADGES_TYPE_SUCCESS: {
        return {
          ...state,
          loading: false,
          badges_type: action.payload,
          error: "",
        }
      }
      case VIEW_BADGES_TYPE_FAILED: {
        return {
          ...state,
          loading: false,
          badges_type: null,
          error: action.payload
        }
      }
      case CREATE_BADGE_SUCCESS: {
        const data = action.payload.data
        return {
          ...state,
          loading: false,
          badges: [...state.badges, data],
          error: "",
        }
      }
      case CREATE_BADGE_FAILED: {
        return {
          ...state,
          loading: false,
          badges: null,
          error: action.payload
        }
      }
      default:
        return state
    }

}

export default BadgesReducer;