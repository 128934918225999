
export const BUY_PLAN = "BUY_PLAN"
export const BUY_PLAN_SUCCESS = "BUY_PLAN_SUCCESS"
export const BUY_PLAN_FAILED = "BUY_PLAN_FAILED"

export const VIEW_PLANS = "VIEW_PLANS"
export const VIEW_PLANS_SUCCESS = "VIEW_PLANS_SUCCESS"
export const VIEW_PLANS_FAILED = "VIEW_PLANS_FAILED"

export const VIEW_MY_SUBSCRIPTIONS = "VIEW_MY_SUBSCRIPTIONS"
export const VIEW_MY_SUBSCRIPTIONS_SUCCESS = "VIEW_MY_SUBSCRIPTIONS_SUCCESS"
export const VIEW_MY_SUBSCRIPTIONS_FAILED = "VIEW_MY_SUBSCRIPTIONS_FAILED"

export const UPDATE_RENEWAL = "UPDATE_RENEWAL"
export const UPDATE_RENEWAL_SUCCESS = "UPDATE_RENEWAL_SUCCESS"
export const UPDATE_RENEWAL_FAILED = "UPDATE_RENEWAL_FAILED"

export const CHANGE_SUBSCRIPTION = "CHANGE_SUBSCRIPTION"
export const CHANGE_SUBSCRIPTION_SUCCESS = "CHANGE_SUBSCRIPTION_SUCCESS"
export const CHANGE_SUBSCRIPTION_FAILED = "CHANGE_SUBSCRIPTION_FAILED"