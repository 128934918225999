import {
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILED
} from './actionTypes'

export const signupUser = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user,
        meta:{
            thunk:true
        }
    }
}

export const signupUserSuccess = (user, {thunk}) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: user,
        meta: {
            thunk
        }
    }
}

export const signupUserFailed = (error, {thunk}) => {
    return {
        type: SIGNUP_USER_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}
