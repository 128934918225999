import React, { useState, useEffect } from 'react'


import profilePicIcon from "../../assets/images/profile-pic-placeholder.png"

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const ProfilePicUpload = ({ image, onImageSelect, isEdit }) => {

  const [selectedImagePreview, setSelectedImagePreview] = useState(null)
  const [availableImagePreview, setAvailableImagePreview] = useState(null)

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const imageHandler = async (e) => {
    setSelectedImagePreview(await toBase64(e.target.files[0]))
  }

  const resetFileInput = () => {
    setSelectedImagePreview(null)
    setAvailableImagePreview(null)
    document.getElementById('imageInput').value = ""; // clearing file input
  }

  useEffect(() => {
    onImageSelect(selectedImagePreview)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImagePreview])

  useEffect(() => {
    if(image !== "https://api.smartht.co.uk/storage/images/users" && image !== null) {
      setAvailableImagePreview(image)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  

  return (
    <div className="profile-pic-upload-wrapper">

      <div className={`profile-pic-upload ${isEdit ? "" : "no-event"}`}>
        <input type="file" className='form-control' onChange={imageHandler} value="" id="imageInput" />
        <img className={`img-placeholder ${availableImagePreview ? "" : "d-none"}`} src={availableImagePreview} alt="" />
        <img className={`img-previewholder ${selectedImagePreview ? "d-block" : "d-none"}`} alt="" src={selectedImagePreview} />
        <img className='img-placeholder z-0' src={profilePicIcon} alt="" />
      </div>

      <div className={`close-icon ${availableImagePreview && isEdit ? "" : "d-none"}`} onClick={resetFileInput}>
        <FontAwesomeIcon icon="fas fa-times" />
      </div>

    </div>
  )
}


export default ProfilePicUpload