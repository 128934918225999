import React,  { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';


import AccountYearlyPlan from './AccountYearlyPlan';
import AccountMonthlyPlan from './AccountMonthlyPlan'
import AccountPlanFeaturesModal from './AccountPlanFeaturesModal';

import arrowIcon from "../../../../assets/images/arrow-right-green-icon.svg"

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const SelectPlan = props => {
  
  const location = useLocation();

  const [ yearlyPlan, setYearlyPlan ] = useState(true) 
  const  [ childrenCount, setChildrenCount ] = useState(1)
  const [ isFeaturesVisible, SetFeaturesVisible ] = useState(false)
  const [ isSwitchOver, setSwitchOver ] = useState(false)

  const handleChangePlan = (type) => {
    return type === 'yearly' ? setYearlyPlan(true) : setYearlyPlan(false)
  }

  const handleChildren = (type) => {
    return type === 'plus' ? setChildrenCount(childrenCount + 1) : setChildrenCount(childrenCount - 1)
  }

  const showFeatures = (e) => {
    e.preventDefault()
    SetFeaturesVisible(true)
  }

  useEffect(() => {
    let currentUrlParams = new URLSearchParams(location.search);
    if(currentUrlParams.get('upgrade')) {
      setYearlyPlan(false)
      setSwitchOver(true)
    }
    else {
      setYearlyPlan(true)
      setSwitchOver(false)
    }
}, [location])

  return (
    <div className='select-plan-wrapper bg-primary-100'>
      <h2 className='text-center text-gray-990 ft-default fw-normal mb-3'>The right plan for your children</h2>
      <h4 className='text-center text-gray-500 ft-default fw-normal mb-4'>Choose plan that works best for you children future</h4>
      
      <div className='choose-term-wrapper d-flex justify-content-center'>
        
        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
          
          <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked={!yearlyPlan} onChange={() => handleChangePlan('monthly') } />
          <label className="btn btn-outline-primary" htmlFor="btnradio1">Monthly</label>

          <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" checked={yearlyPlan}  onChange={() => handleChangePlan('yearly') } />
          <label className="btn btn-outline-primary" htmlFor="btnradio2">Yearly</label>

        </div>

      </div>

      <div className='card mt-4'>
        
        <div className='card-body p-3 p-md-4'>
          
          <div className='children-container'>
            <p className='text-center text-gray-950 text-base'>Choose the number of children</p>
            <div className="d-flex m-auto children-box justify-content-center mt-2 border-primary-900 rounded-lg" >
              <div className={`bg-primary-900 text-white text-base counter-btn d-flex justify-content-center align-items-center ${childrenCount === 1 ? 'disabled' : ''}`} onClick={() => handleChildren('minus')}>
                <FontAwesomeIcon icon={['fas', 'fa-minus']} />
              </div>
              <div className='value-box d-flex justify-content-center align-items-center flex-grow-1 text-base fw-medium'>{childrenCount}</div>
              <div className={`bg-primary-900 text-white text-base counter-btn d-flex justify-content-center align-items-center ${childrenCount === 3 ? 'disabled' : ''}`} onClick={() => handleChildren('plus')}>
                <FontAwesomeIcon icon={['fas', 'fa-plus']} />
              </div>
            </div>
          </div>

          {
            yearlyPlan ? (
              <AccountYearlyPlan childrenCount={childrenCount}  />
            ) : (
              <AccountMonthlyPlan childrenCount={childrenCount} />
            )
          }

          <div className='mt-3 text-center'>
            <div className=''>
              <a className='ft-default see-all text-base me-3' href="/#" onClick={(e) => showFeatures(e)}>See all features</a>
              <img src={arrowIcon} alt="" />
            </div>
          </div>

        </div>

      </div>
      <AccountPlanFeaturesModal visible={isFeaturesVisible} 
            onClose = { () => {SetFeaturesVisible(!isFeaturesVisible)} }/>
    </div>
  )
}

export default SelectPlan