import { all, fork } from "redux-saga/effects"

import LayoutSaga from "./layout/saga"

import LoginSaga from "./auth/login/saga"
import SignupSaga from './auth/signup/saga'
import OtpSaga from "./auth/otp/saga"
import ResetPasswordSaga from "./auth/reset-password/saga"
import ContactAdminSaga from "./auth/contact-admin/saga"

import PlanAndPricesSaga from "./plans-and-prices/saga"

import GradesSaga from "./settings/grades/saga"
import KeyStageSaga from "./settings/keystage/saga"
import ExamBoardsSaga from "./settings/exam-boards/saga"

import StudentSaga from "./student/saga"
import SubjectSaga from "./subject/saga"
import RewardSaga from "./reward/saga"
import RewardTypeSaga from "./reward-type/saga"
import LessonScoreSaga from "./lesson-score/saga"
import BadgesSaga from "./badges/saga"

import BillingSaga from "./billing/saga"
import CardSaga from "./card/saga"
import NotificationsSaga from "./notifications/saga"
import FaqSaga from "./faq/saga"

export default function* rootSaga() {
    yield all([
      fork(LayoutSaga),
      fork(SignupSaga),
      fork(LoginSaga),
      fork(OtpSaga),
      fork(ResetPasswordSaga),
      fork(ContactAdminSaga),
      fork(PlanAndPricesSaga),
      fork(GradesSaga),
      fork(KeyStageSaga),
      fork(ExamBoardsSaga),
      fork(StudentSaga),
      fork(SubjectSaga),
      fork(RewardSaga),
      fork(RewardTypeSaga),
      fork(LessonScoreSaga),
      fork(BadgesSaga),
      fork(BillingSaga),
      fork(CardSaga),
      fork(NotificationsSaga),
      fork(FaqSaga)
    ])
  }