import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { fulfilled, clean } from 'redux-saga-thunk'

import { useDispatch, useSelector } from 'react-redux'

import { Collapse, CardBody, Card, CardHeader} from 'reactstrap';

import { useFormik } from 'formik'
import parse from 'html-react-parser';

import { contactAdmin as onContactAdmin } from '../../store/auth/contact-admin/actions';
import { viewFaq as onViewFaq } from '../../store/faq/actions';

import helpBlock from "../../assets/images/help-block-img.png"
 
import ContactQueryReceivedModal from '../ContactUs/ContactQueryReceivedModal';

const Help = props => {

    const dispatch = useDispatch()

    const [ isReceivedModalVisible, setReceivedModalVisible ] = useState(false)

    const [ selectedTab, setSelectedTab ] = useState(0)

    const { faq, get_success, get_contact_success } = useSelector(state => ({
        faq: state.FaqReducer.faq,
        get_success: fulfilled(state, "VIEW_FAQ"),
        get_contact_success: fulfilled(state, "CONTACT_ADMIN")
    }))

    const initialValues = {
        message: ""
    }

    const onSubmit = values => {
        let data = {
          subject: "FAQ",
          message: values.message
        }
        dispatch(onContactAdmin(data))
    }

    const validate = values => {

        let errors = {}
    
        if(!values.message) {
          errors.message = "Message is required!"
        }
    
        return errors
    
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    const handleQuestionTab = (index) => {
        if(index === selectedTab) {
            setSelectedTab(-1)
        }
        else {
            setSelectedTab(index)
        }
    }

    useEffect(() => {
        dispatch(onViewFaq())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(get_contact_success) {
        dispatch(clean("CONTACT_ADMIN"))
        setReceivedModalVisible(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_contact_success])


  return (
    <div className='help-wrapper'>
        <div className='help-container'>
            <h3 className='text-center'>Welcome to SqillUP FAQ centre</h3>
            <div className='text-center mt-3 img-block'>
                <img src={helpBlock} alt="" />
            </div>
            <div className='questions-block'>
                <h4>Frequently asked question</h4>
                <div className='questions-container mt-4'>
                    {
                        get_success && faq.map((item, index) => {
                            return (
                                <Card key={index} className='mb-3' onClick={() => {handleQuestionTab(index)}}>
                                    <CardHeader className='pointer d-flex justify-content-between'>
                                        <h6 className='title text-primary-950 text-base'>{item.question}</h6>
                                        <div className='state-icons'>
                                            {
                                                (selectedTab === index) ? (
                                                    <div className='icon-circle bg-primary-500 fw-medium text-white'>-</div>
                                                ) : (
                                                    <div className='icon-circle bg-primary-500 fw-medium text-white'>+</div>
                                                )
                                            }                                            
                                        </div>
                                    </CardHeader>
                                    <Collapse isOpen={index === selectedTab}>
                                        <CardBody>
                                            {parse(item.answer)}
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            )
                        })
                    }
                </div>
            </div>
            <div className='help-form-box'>
                <form onSubmit={formik.handleSubmit}>
                    <p className='text-base text-center fw-medium help-link'>Can't find your question here? Contact us at <Link className='text-base'to="/parent/contact">contact@sqillup.uk.com</Link></p>
                    <p className='text-center fw-medium mb-3'>OR</p>
                    <div className="input-box mb-4">
                        <textarea className="form-control" name="message" placeholder="" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.message}></textarea>
                        { formik.touched.message && <p className="mt-1 text-sm text-red-500">{formik.errors.message}</p> }
                    </div>
                    <button className='btn btn-primary submit-btn'>Submit</button>
                </form>
            </div>
        </div>
        <ContactQueryReceivedModal visible={isReceivedModalVisible}
            onClose = { () => {setReceivedModalVisible(!isReceivedModalVisible)} } />
    </div>
  )
}

export default Help