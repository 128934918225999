
export const topMenu = [
    {
        icon: require("../assets/images/home-sidebar-icon.png"),
        title: "Home",
        path: "/parent/dashboard",
        altIcon: require("../assets/images/home-sidebar-active-icon.png")
    },
    {
        icon: require("../assets/images/profile-sidebar-icon.png"),
        title: "Student Profile",
        path: "/parent/student-profile",
        altIcon: require("../assets/images/profile-sidebar-active-icon.png")
    },
    {
        icon: require("../assets/images/growth-sidebar-icon.png"),
        title: "Growth",
        path: "/parent/growth/score",
        altIcon: require("../assets/images/growth-sidebar-active-icon.png")
    },
    {
        icon: require("../assets/images/activity-sidebar-icon.png"),
        title: "Activity",
        path: "/parent/activity",
        altIcon: require("../assets/images/activity-sidebar-active-icon.png")
    }
  ];

  export const bottomMenu = [
    {
        icon: require("../assets/images/swap-sidebar-icon.png"),
        title: "Swap"
    },
    {
        icon: require("../assets/images/contact-sidebar-icon.png"),
        title: "Contact",
        path: "/parent/contact",
        altIcon: require("../assets/images/contact-sidebar-active-icon.png")
    },
    {
        icon: require("../assets/images/help-sidebar-icon.png"),
        title: "Help",
        path: "/parent/help",
        altIcon: require("../assets/images/help-sidebar-active-icon.png")
    }
  ]

