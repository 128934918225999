import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean } from 'redux-saga-thunk'
import { Tooltip } from "reactstrap"

import growthBlockImg  from '../../../assets/images/growth-block-img.png'

import CreateBadgeModal from './CreateBadgeModal';
import GrowthBadgeCard from './GrowthBadgeCard';

import { viewBadges as onViewBadges } from '../../../store/actions';
import { viewSubject as onViewSubject } from '../../../store/subject/actions';
import { viewBadgesType as onViewBadgesType } from '../../../store/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const GrowthBadges = props => {

  const dispatch = useDispatch()

  const [ isBadgesModalVisible, setBadgesModalVisible ] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)


  const { user, badges, subject, badges_type, get_success, get_subject_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    badges: state.BadgesReducer.badges,
    badges_type: state.BadgesReducer.badges_type,
    subject: state.SubjectReducer.subject,
    get_success: fulfilled(state, "VIEW_BADGES"),
    get_badges_type_success:  fulfilled(state, "VIEW_BADGES_TYPE"),
    get_subject_success: fulfilled(state, "VIEW_SUBJECT")
  }))

  const onClose = () => {
    setBadgesModalVisible(!isBadgesModalVisible)
  }

  const handleCreateBadge = () => {
    setBadgesModalVisible(!isBadgesModalVisible)
  }

  useEffect(() => {
    let data = {
      user_id: user.id
    }
    dispatch(onViewBadges(data))
    dispatch(onViewSubject())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(get_success) {
      console.log(badges)
      dispatch(clean("VIEW_BADGES"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  useEffect(() => {
    dispatch(onViewBadgesType())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className='growth-inner-wrapper badges-wrapper'>
      <div className='btn-container d-flex justify-content-end'>
        <div className='btn tab-btn sp-btn' onClick={handleCreateBadge}>
        <span className='circle'>
          <FontAwesomeIcon icon="fa-solid fa-plus" />
        </span>
          <span className='d-none d-xl-inline-block'>Create Badges</span>
        </div>
      </div>
      <div className='left-box bg-white'>
        
        { badges && badges.length === 0 ? (
          <div className='no-results'>
            <div className='img-container'>
              <img src={growthBlockImg} alt="" />
            </div>
            <div className='desp-box'>
              <h6 className='title'>No Badge created</h6>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum reprehenderit est assumenda doloribus accusantium nobis eligendi atque repudiandae ipsam aspernatur</p>
            </div>
          </div>
        ) : (
          <div className='badges-box'>
            <div className='d-flex align-items-center'>
              <h3 className='text-gray-950 ft-default fw-normal'>Badges</h3>
              <h4 className='ms-4 text-primary-500' id="infoTooltip"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></h4>
              <Tooltip
                isOpen={tooltipOpen}
                placement="right"
                target="infoTooltip"
                toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                To create new reward click on the create new button
              </Tooltip>
            </div>
            <h3 className='text-gray-950 ft-default fw-semibold mt-3 mb-5'>Hello Naina, <span className='fw-normal'>We have some badges for you!</span></h3>
            <div className='row'>
              { get_subject_success && badges && badges.map((item, index) => {
                return (
                  <div className='col-xs-12 col-sm-4 col-md-3'>
                    <GrowthBadgeCard badge={item} subject={subject} badges_type={badges_type} />
                  </div>
                )
              }) }
            </div>
          </div>
        ) }
      </div>
      <CreateBadgeModal 
        visible={isBadgesModalVisible}
        subject={subject}
        badges_type={badges_type}
        onClose = {onClose}
      />
    </div>
  )
}


export default GrowthBadges