import React, { useState, useEffect } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import { useSelector } from 'react-redux';
import _ from 'underscore'

import PlanSettings from './PlanSettings/PlanSettings'
import BillingSettings from './BillingSettings/BillingSettings'
import PurchaseHistory from './PurchaseHistory/PurchaseHistory'

import ChosenPlan from '../../components/Common/ChosenPlan'

const ProfileSettings = props => {

  const { user } = useSelector(state => ({
    user: state.LoginReducer.user
  }))

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  
  const [ planDetails, setplanDetails ] = useState({})

  // Toggle active state for Tab
  const toggle = tab => {
      if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  useEffect(() => {
    if(!_.isEmpty(user))
    setplanDetails(user.plans[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className='profile-settings-wrapper'>
      <h5 className='title'>Subscription</h5>
      <div className='profile-settings-box mt-4'>
        <Nav tabs>
          <NavItem>
              <NavLink onClick={() => { toggle('1'); }}>
                  <div className={`btn btn-big ${currentActiveTab === '1' ? "btn-primary" : "bg-white"}`}>Plan</div>
              </NavLink>
          </NavItem>
          <ChosenPlan id={planDetails.id}>
            <NavItem>
                <NavLink onClick={() => { toggle('2'); }}>
                    <div className={`btn btn-big ${currentActiveTab === '2' ? "btn-primary" : "bg-white"}`}>Billing</div>
                </NavLink>
            </NavItem>
          </ChosenPlan>
          <NavItem>
              <NavLink onClick={() => { toggle('3'); }}>
                  <div className={`btn btn-big ${currentActiveTab === '3' ? "btn-primary" : "bg-white"}`}>Purchase History</div>
              </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <PlanSettings />
            </TabPane>
            <TabPane tabId="2">
              <BillingSettings />
            </TabPane>
            <TabPane tabId="3">
              <PurchaseHistory />
            </TabPane>
        </TabContent>
      </div>
    </div>
  )
}


export default ProfileSettings