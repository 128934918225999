export const sessionData = {
    
    setAuthUser: (data) => {
        localStorage.setItem('authUser', data);
    },
    setParentId: (data) => {
        localStorage.setItem('parentId', data);
    },
    setEmailVerified: (data) => {
        localStorage.setItem('email_verified', data);
    },
    setParentEmail: (data) => {
        localStorage.setItem('parent_email', data);
    },
    getAuthUser: () => {
        return localStorage.getItem('authUser');
    },
    getParentId: () => {
        return localStorage.getItem('parentId');
    },
    getEmailVerified: () => {
        return localStorage.getItem('email_verified');
    },
    getParentEmail: () => {
        return localStorage.getItem('parent_email');
    },
    removeAuthUser: () => {
        localStorage.removeItem('authUser');
    },
    removeParentId: () => {
        localStorage.removeItem('parentId');
    },
    removeEmailVerified: () => {
        localStorage.removeItem('email_verified');
    },
    removeParentEmail: () => {
        localStorage.removeItem('parent_email');
    }
}