import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';

import checkImg from "../../../../assets/images/welcome-check-img.png"
import AccountStepper from '../AccountStepper/AccountStepper'

const PaymentSuccess = props => {

  const navigate = useNavigate()
  const location = useLocation();

  const { user } = useSelector(state => ({
    user: state.LoginReducer.user
  }))

  useEffect(() => {
    if(location.pathname.includes("payment-success")) {
      setTimeout(()=> {
        let currentUrlParams = new URLSearchParams(location.search);
        currentUrlParams.set('sid', 5)
        navigate( { pathname: "/parent/add-student", search: currentUrlParams.toString() }, { replace: true })
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  return (
    <div className='setup-confirmation-wrapper bg-white'>
        <AccountStepper />
        <div className='welcome-box bg-primary-100 w-100 flex-column d-flex justify-content-center align-items-center'>
            <img src={checkImg} alt="" />
            <div className='message text-center'>
                <h4 className='text-gray-950 title'>Payment Confirm</h4>
                <p className='text-base text-gray-400'>{user.full_name} You have Successfully paid.</p>
            </div>
        </div>
    </div>
  )
}

export default PaymentSuccess