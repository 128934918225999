import React, { useState } from 'react'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const GrowthBadgeCard = ({badge, subject, badges_type}) => {

    const [isBadgeHover, setBadgeHover] = useState(false);

    const getSubjectColor = (id) => {
        let data = subject?.filter(item => {
            return item.id === id
        })
        if(data === undefined || data === null || data.length === 0){
            return ""
        }
        else {
            return data[0].color
        }
    }

    const getBadgeType = (id) => {
        let data = badges_type?.filter(item => {
          return item.id === id
        })
        if(data === undefined || data === null || data.length === 0){
          return ""
        }
        else {
          return data[0].image
        }
    }

  return (
    <div className='badges-card' style={{backgroundColor: getSubjectColor(parseInt(badge.subject_id))}} onMouseEnter={() => setBadgeHover(true)} onMouseLeave={() => setBadgeHover(false)}>
        <div className='img-box'>
            <img src={getBadgeType(parseInt(badge.badge_typ_id))} alt="" />
         </div>
         <h6 className='text-white text-center fw-semibold py-3'>{badge.title}</h6>
         <div className={`locked-box ${isBadgeHover ? "fade-in" : "fade-out"}`} style={{backgroundColor: getSubjectColor(parseInt(badge.subject_id))}}>
            <div className='locked-content d-flex flex-column justify-content-center align-items-center h-100'>
                <FontAwesomeIcon icon="fas fa-lock" />
                <div className='mt-2'>{badge.description}</div>
            </div>
         </div>
    </div>
  )
}


export default GrowthBadgeCard