import {
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    RESEND_OTP,
    RESEND_OTP_SUCCESS,
    RESEND_OTP_FAILED
} from './actionTypes';


export const verifyOtp = (data) => {
    return {
        type: VERIFY_OTP,
        payload: {data},
        meta:{
            thunk:true
        }
    }
}

export const verifyOtpSuccess = (data, {thunk}) => {
    return {
        type: VERIFY_OTP_SUCCESS,
        payload: {data},
        meta: {
            thunk
        }
    }
}

export const verifyOtpFailed = (data, {thunk}) => {
    return {
        type: VERIFY_OTP_FAILED,
        payload: data,
        meta: {
            thunk
        }
    }
}

export const resendOtp = (data) => {
    return {
        type: RESEND_OTP,
        payload: {data},
        meta:{
            thunk:true
        }
    }
}

export const resendOtpSuccess = (data, {thunk}) => {
    return {
        type: RESEND_OTP_SUCCESS,
        payload: {data},
        meta: {
            thunk
        }
    }
}

export const resendOtpFailed = (data, {thunk}) => {
    return {
        type: RESEND_OTP_FAILED,
        payload: data,
        meta: {
            thunk
        }
    }
}