import {
   VIEW_FAQ_SUCCESS,
   VIEW_FAQ_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    faq: null
}

const FaqReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case VIEW_FAQ_SUCCESS: {
        return {
          ...state,
          loading: false,
          faq: action.payload,
          error: "",
        }
      }
      case VIEW_FAQ_FAILED: {
        return {
          ...state,
          loading: false,
          faq: null,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default FaqReducer;