import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fulfilled } from 'redux-saga-thunk';
import _ from 'underscore'

import { viewStudent as onViewStudent } from '../../store/student/actions';
import StudentDetailsCard from './StudentDetailsCard';
import ChosenPlan from '../../components/Common/ChosenPlan';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


library.add(fas, fab);

const StudentProfile = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [ planDetails, setplanDetails ] = useState({})

  const { user, students, get_student } = useSelector(state => ({
    user: state.LoginReducer.user,
    students: state.StudentReducer.student,
    get_student: fulfilled(state, "VIEW_STUDENT")
  }))

  const handleAddStudent = () => {
    navigate( { pathname: "/parent/add-student", search: "?sid=5&plan=pro&subscription=monthly" })
  }

  useEffect(() => {
    if(!_.isEmpty(user)) {
      let data = { parent_id:user.id }
      dispatch(onViewStudent(data))
      setplanDetails(user.plans[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className='student-profile-wrapper'>
        <div className='d-flex align-items-center mb-5'>
          <h5>Student Account Details</h5>
          <ChosenPlan id={planDetails?.id}>
            { get_student && <div className='btn btn-big student-btn ms-auto' onClick={handleAddStudent}><FontAwesomeIcon className="text-white text-h6 pointer me-3" icon="fas fa-plus-circle" />Add Student</div> }
          </ChosenPlan>
        </div>
        <div className='student-profile-box mt-4'>
          { get_student && students.map((item, index) => (
                <StudentDetailsCard key={index} student={item} />
            ))
          }
        </div>
    </div>
  )
}

export default StudentProfile