import { takeEvery, put, call } from "redux-saga/effects"

import { SIGNUP_USER } from './actionTypes'
import { signupUserSuccess, signupUserFailed } from './actions'

import { signupApi } from "../../../helpers/api/api"

import { sessionData } from "../../../constants/sessiondata"

function* userSignup({ payload: user , meta }) {
    try {
        const response = yield call(signupApi,user)
        const { token_type, token } = response
        sessionData.setAuthUser(JSON.stringify(`${token_type} ${token}`))
        yield put(signupUserSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(signupUserFailed(message, meta))
    }
}

function* SignupSaga() {
    yield takeEvery(SIGNUP_USER, userSignup)
}

export default SignupSaga