import {
    VIEW_BADGES,
    VIEW_BADGES_SUCCESS,
    VIEW_BADGES_FAILED,
    VIEW_BADGES_TYPE,
    VIEW_BADGES_TYPE_SUCCESS,
    VIEW_BADGES_TYPE_FAILED,
    CREATE_BADGE,
    CREATE_BADGE_SUCCESS,
    CREATE_BADGE_FAILED
} from './actionTypes'

export const viewBadges = (badge) => {
    return {
        type: VIEW_BADGES,
        payload: badge,
        meta:{
            thunk:true
        }
    }
}

export const viewBadgesSuccess = (badges, {thunk}) => {
    return {
        type: VIEW_BADGES_SUCCESS,
        payload: badges,
        meta: {
            thunk
        }
    }
}

export const viewBadgesFailed = (badges_type, {thunk}) => {
    return {
        type: VIEW_BADGES_FAILED,
        payload: badges_type,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewBadgesType = () => {
    return {
        type: VIEW_BADGES_TYPE,
        meta:{
            thunk:true
        }
    }
}

export const viewBadgesTypeSuccess = (badges_type, {thunk}) => {
    return {
        type: VIEW_BADGES_TYPE_SUCCESS,
        payload: badges_type,
        meta: {
            thunk
        }
    }
}

export const viewBadgesTypeFailed = (badges_type, {thunk}) => {
    return {
        type: VIEW_BADGES_TYPE_FAILED,
        payload: badges_type,
        error: true,
        meta: {
            thunk
        }
    }
}

export const createBadge = (reward) => {
    return {
        type: CREATE_BADGE,
        payload: reward,
        meta:{
            thunk:true
        }
    }
}
  
export const createBadgeSuccess = ({response, data}, { thunk }) => ({
    type: CREATE_BADGE_SUCCESS,
    payload: {response, data},
    meta: {
        thunk
    }
})
  
export const createBadgeFailed = (error, { thunk }) => ({
    type: CREATE_BADGE_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})