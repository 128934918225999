import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean, rejected } from "redux-saga-thunk"

import AccountStepper from '../AccountStepper/AccountStepper'
import SimpleUpload from '../../../../components/Common/SimpleUpload';
import SelectInputGroup from '../../../../components/Common/SelectInputGroup';

import { getKeyStages as onGetKeyStages } from '../../../../store/settings/keystage/actions';
import { getGrades as onGetGrades } from '../../../../store/settings/grades/actions';
import { getExamBoards as onGetExamBoards } from '../../../../store/settings/exam-boards/actions';

import { addStudent as onAddStudent } from '../../../../store/student/actions';

import { Alert } from "reactstrap"

import { useFormik } from 'formik';
import DatePicker from "react-datepicker";

import moment from 'moment';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const AddStudent = props => {

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch()

  const [ showPassword, setShowPassword ] = useState(false)
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)
  const [ dob, setDob ] = useState(null)

  const { user, keystages, grades, examboards, add_student_success, add_student_failed, add_student_error} = useSelector(state => ({
   user: state.LoginReducer.user,
   keystages: state.KeyStageReducer.keystages,
   grades: state.GradesReducer.grades,
   examboards: state.ExamBoardsReducer.examboards,
   add_student_success: fulfilled(state, "ADD_STUDENT"),
   add_student_failed: rejected(state, "ADD_STUDENT"),
   add_student_error: state.StudentReducer.error
  }))

  const initialValues = {
    image: "",
    full_name: "",
    email: "",
    school_name: "",
    grade_id: null,
    key_stage_id: null,
    exam_board_id: null,
    dob: "",
    parent_id: user.id,
    user_name: "",
    password: "",
    confirm_password: ""
  }

  const onSubmit = () => {
    dispatch(onAddStudent(formik.values))
  }

  const validate = (values) => {

    let errors = {}

    if(!values.image) {
      errors.image = "Profile image required"
    }

    if(!values.full_name) {
      errors.full_name = "Full Name required!"
    }

    if(!values.email) {
        errors.email = "Email required"
    }
    else if(!/^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+.)+[a-z]{2,5}$/i.test(values.email)) {
        errors.email = "Enter a valid email address"
    }

    if(!values.school_name) {
      errors.school_name = "School Name required!"
    }

    if(!values.grade_id) {
        errors.grade_id = "Grade required!"
    }

    if(!values.key_stage_id) {
      errors.key_stage_id = "Key Stage required!"
    }

    if(!values.exam_board_id) {
      errors.exam_board_id = "Exam Board required!"
    }

    if(!values.dob) {
      errors.dob = "DOB required!"
    }

    if(!values.user_name) {
      errors.user_name = "Username required!"
    }

    if(!values.password) {
        errors.password = "Password is required!"
    }
    else if(values.password.length < 6) {
        errors.password = "Password should contain minimum 6 characters!"
    }

    if(!values.confirm_password) {
        errors.confirm_password = "Password is required!"
    }
    else if(values.password !== values.confirm_password) {
        errors.confirm_password = "Password did not match!"
    }

    return errors

  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  const handleOnSelectGrades = (id) => {
    formik.setFieldValue("grade_id", id)
  }

  const handleOnSelectKeystage = (id) => {
   formik.setFieldValue("key_stage_id", id)
  }

  const handleOnSelectExamBoards = (id) => {
    formik.setFieldValue("exam_board_id", id)
  }

  const handleDate = (date) => {
    setDob(date)
    setTimeout(() => {
      formik.setFieldValue("dob", moment(date).format('MM/DD/YYYY'))
    }, 100)
  }

  const handleImage = (image) => {
    formik.setFieldValue("image", image)
  }

  useEffect(() => {
    dispatch(onGetKeyStages())
    dispatch(onGetGrades())
    dispatch(onGetExamBoards())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(add_student_success) {
      
      dispatch(clean("ADD_STUDENT"))
      
      let currentUrlParams = new URLSearchParams(location.search);
      let plan = user.plans[0];
      
      if(plan.id === 2) {
        currentUrlParams.set('sid', 4)
      }
      else {
        currentUrlParams.set('sid', 6)
      }
      
      navigate(
        {
            pathname: "/parent/welcome",
            search: currentUrlParams.toString()
        },
        { replace: true }
      )
    }
    if(add_student_failed) {
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [add_student_success])

  return (
    <div className='add-student-wrapper bg-primary-100'>
      <AccountStepper />
      <form name="addStudentForm" onSubmit={formik.handleSubmit}>
        <div className='row form-container'>
          <div className='col-lg-12 col-xl-8'>
            <div className='card rounded-lg h-100 mb-5'>
              <div className='card-header text-base'>Add Student</div>
              <div className='card-body p-4'>
              {(add_student_error && add_student_failed) && ( <Alert color="danger">{add_student_error}</Alert>)}
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='mb-4'>
                      <SimpleUpload onImageSelect={handleImage} />
                      { formik.touched.image && <p className="mt-1 text-sm text-red-500">{formik.errors.image}</p> }
                    </div>
                  </div>
                  
                  <div className='col-md-6'>
                    <div className='input-box'>
                      <label className="form-label">Student full name</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-user" />
                        </span>
                        <input type="text" className="form-control" placeholder='Enter your student fullname' name="full_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.full_name} />
                      </div>
                      { formik.touched.full_name && <p className="mt-1 text-sm text-red-500">{formik.errors.full_name}</p> }
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='input-box'>
                      <label className="form-label">Email</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-envelope" />
                        </span>
                        <input type="email" className="form-control" placeholder='lucifer@123gmail.com' name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                      </div>
                      { formik.touched.full_name && <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p> }
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='input-box'>
                      <label className="form-label">School name</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-school" />
                        </span>
                        <input type="text" className="form-control" placeholder='Enter your school name' name="school_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.school_name} />
                      </div>
                      { formik.touched.school_name && <p className="mt-1 text-sm text-red-500">{formik.errors.school_name}</p> }
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='input-box'>
                      <SelectInputGroup 
                        label="Class" 
                        id="ClassSelect" 
                        icon="fa-graduation-cap"
                        placeholder="Grade (eg. G-12)" 
                        data={grades}
                        onChange={handleOnSelectGrades}
                      />
                      { formik.touched.grade_id && <p className="mt-1 text-sm text-red-500">{formik.errors.grade_id}</p> }
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='input-box'>
                      <SelectInputGroup 
                        label="Key stage" 
                        id="keyStageSelect" 
                        icon="fa-graduation-cap"
                        placeholder="Key stage (eg. KS-3)"
                        data={keystages} 
                        onChange={handleOnSelectKeystage}
                      />
                      { formik.touched.key_stage_id && <p className="mt-1 text-sm text-red-500">{formik.errors.key_stage_id}</p> }
                    </div>
                  </div>
                  
                  <div className='col-md-6'>
                    <div className='input-box'>
                      <SelectInputGroup 
                      label="Exam Board" 
                      id="examBoardSelect" 
                      icon="fa-file-alt" 
                      placeholder="Exam board (eg. AQA)"
                      data={examboards} 
                      onChange={handleOnSelectExamBoards} 
                      />
                      { formik.touched.exam_board_id && <p className="mt-1 text-sm text-red-500">{formik.errors.exam_board_id}</p> }
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='input-box readonly-box'>
                      <label className="form-label">DOB</label>
                      <div className="input-group">
                        <span className="input-group-text pointer">
                          <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-calendar-days" />
                        </span>
                        <DatePicker 
                          className='form-control pointer' 
                          placeholderText={'DD/MM/YYYY'} 
                          id="example-datepicker" 
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select" 
                          selected={dob} 
                          onChange={(date) => handleDate(date)} 
                          autoComplete="off" 
                        />
                      </div>
                      { formik.touched.dob && <p className="mt-1 text-sm text-red-500">{formik.errors.dob}</p> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-xl-4'>
            <div className='card rounded-lg h-100 mb-5'>
            <div className='card-header text-base'><span className='opacity-0'>Add Student 1</span></div>
              <div className='card-body'>
                <div className='input-box'>
                  <label className="form-label">Student username</label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-user" />
                    </span>
                    <input type="text" className="form-control" placeholder='Enter your student username' name="user_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.user_name} />
                  </div>
                  { formik.touched.user_name && <p className="mt-1 text-sm text-red-500">{formik.errors.user_name}</p> }
                </div>
                <div className='input-box'>
                  <label className="form-label">Password</label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-lock" />
                    </span>
                    <input type={ showPassword ? 'text' : 'password' } className="form-control" placeholder='Enter Password' name="password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.password} />
                    <span className="input-group-text">
                      <FontAwesomeIcon className='pointer text-sm text-primary-950' icon={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowPassword(!showPassword)}} />
                    </span>
                  </div>
                  { formik.touched.password && <p className="mt-1 text-sm text-red-500">{formik.errors.password}</p> }
                </div>
                <div className='input-box'>
                  <label className="form-label">Confirm Password</label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-lock" />
                    </span>
                    <input type={ showConfirmPassword ? 'text' : 'password' } className="form-control" placeholder='Enter Confirm Password' name="confirm_password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.confirm_password} />
                    <span className="input-group-text">
                      <FontAwesomeIcon className='pointer text-sm text-primary-950' icon={`fa-solid ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowConfirmPassword(!showConfirmPassword)}} />
                    </span>
                  </div>
                  { formik.touched.confirm_password && <p className={`mt-1 text-sm  ${formik.errors.confirm_password ? 'text-red-500' : ''}`}>{formik.errors.confirm_password}</p> }
                </div>
                <div className="my-4">
                  <button type="submit" className="btn btn-primary">{'Save & Continue'}</button>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}


export default AddStudent