import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled } from 'redux-saga-thunk';
import _ from 'underscore'

import { viewStudent as onViewStudent } from '../../store/student/actions';

const MyStudent = props => {

    const dispatch = useDispatch();
    const [ allStudents, setAllStudents ] = useState([])

    const { user, student, get_student } = useSelector(state => ({
        user: state.LoginReducer.user,
        student: state.StudentReducer.student,
        get_student: fulfilled(state, "VIEW_STUDENT")
    }))

    useEffect(() => {
        if(user) {
            let data = { parent_id:user.id }
            dispatch(onViewStudent(data))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        setAllStudents(student)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_student])

  return (
    <div className='dashboard-my-student-wrapper mt-5'>
        <h5 className='mb-3 fw-medium'>My Students</h5>
        <div className='row'>
            { allStudents.length !== undefined && allStudents.map((item, index) => (
                <div key={index} className='col-md-4'>
                    <div className='card shadow h-100 content'>
                        <div className='card-body h-100 details'>
                            <div className='d-flex mb-5'>
                                <div className='img-wrapper me-3'>
                                    <img src={item.image} alt="" />
                                </div>
                                <div>
                                    <p className='text-sm mb-2 fw-semibold'>{item.full_name}</p>
                                    <p className='text-xs mb-1'>{item.student_detail?.school_name}</p>
                                    <p className='text-xs'>{item.student_detail?.key_stage?.name}</p>
                                </div>
                            </div>
                            {/*<h6 className='text-orange-400 fw-medium plan'>{item.plan}</h6>*/}
                        </div>
                    </div>
                </div>
              ))
            }
        </div>
    </div> 
  )
}

export default MyStudent