import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap"
import { Progress } from 'reactstrap';
import zxcvbn from 'zxcvbn';

import { useFormik } from 'formik'

import closeIcon from "../../assets/images/modal-close-icon.svg"

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const UpdatePasswordModal = ({ visible, onClose, onSubmitData } ) => {

    const [ showOldPassword, setShowOldPassword ] = useState(false)
    const [ showNewPassword, setShowNewPassword ] = useState(false)
    const [ showNewConfirmPassword, setShowNewConfirmPassword ] = useState(false)
    const [ passwordStrengthValue, setPasswordStrengthValue ] = useState(0)

    const initialValues = {
        old_password: "",
        new_password: "",
        confirm_new_password: ""
    }

    const onSubmit = values => {
        onSubmitData(values)
        onClose()
    }

    const validate = values => {

        let errors = {}

        if(!values.old_password) {
            errors.old_password = "Old Password is required!"
        }
        
        if(!values.new_password) {
            errors.new_password = "New Password is required!"
        }
        else if(values.new_password.length < 6) {
            errors.new_password = "Password should contain minimum 6 characters!"
        }

        if(!values.confirm_new_password) {
            errors.confirm_new_password = "New Password is required!"
        }
        else if(values.new_password !== values.confirm_new_password) {
            errors.confirm_new_password = "Password did not match!"
        }

        return errors
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    const handlePasswordChange = (value) => {
        formik.setFieldValue("new_password", value)
        console.log(zxcvbn(value).score)
        setPasswordStrengthValue((zxcvbn(value).score))
    }

    useEffect(() => {
        if(visible) {
            formik.resetForm()
            setPasswordStrengthValue(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody>
        <div className='update-password-modal-wrapper content text-center'>
            <div className='title'>
                <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
            </div>
            <div className='desp text-start mt-5'>
                <div className='row'>
                    <div className='col-md-10 offset-md-1'>
                        <form name="updatePasswordForm" onSubmit={formik.handleSubmit}>
                            <h3 className='text-gray-950 ft-default fw-regular title'>Change password</h3>
                            <div className='input-box auth-box'>
                                <label htmlFor="old_password" className='mb-3'>Current Password</label>
                                <div className="input-group">
                                    <input className='form-control' type={ showOldPassword ? 'text' : 'password' } id="old_password" name="old_password" placeholder='Eg: enter current password' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.old_password} />
                                    { (formik.touched.old_password && !formik.errors.old_password) &&  
                                        <span className='input-group-text'>
                                            <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
                                            <FontAwesomeIcon className='pointer text-sm text-primary-950 ms-3' icon={`fa-solid ${showOldPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowOldPassword(!showOldPassword)}} />
                                        </span>
                                    }
                                </div>
                                { formik.touched.old_password && <p className="mt-1 text-sm text-red-500">{formik.errors.old_password}</p> }
                            </div>
                            <div className='input-box auth-box'>
                                <label htmlFor="new_password" className='mb-3'>New Password</label>
                                <div className="input-group">
                                    <input className='form-control' type={ showNewPassword ? 'text' : 'password' } id="new_password" name="new_password" placeholder='************' onBlur={formik.handleBlur} onChange={(e) => {handlePasswordChange(e.target.value)}} value={formik.values.new_password} />
                                    { (formik.touched.new_password && !formik.errors.new_password) &&  
                                        <span className='input-group-text'>
                                            <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
                                            <FontAwesomeIcon className='pointer text-sm text-primary-950 ms-3' icon={`fa-solid ${showNewPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowNewPassword(!showNewPassword)}} />
                                        </span>
                                    }
                                </div>
                                { formik.touched.new_password && <p className="mt-1 text-sm text-red-500">{formik.errors.new_password}</p> }
                            </div>
                            <Progress value={passwordStrengthValue} max="4" />
                            <div className='input-box auth-box'>
                                <label htmlFor="new_password" className='mb-3'>Repeat New Password</label>
                                <div className="input-group">
                                    <input className='form-control' type={ showNewConfirmPassword ? 'text' : 'password' } id="confirm_new_password" name="confirm_new_password" placeholder='************' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.confirm_new_password} />
                                    { (formik.touched.confirm_new_password && !formik.errors.confirm_new_password) &&  
                                        <span className='input-group-text'>
                                            <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
                                            <FontAwesomeIcon className='pointer text-sm text-primary-950 ms-3' icon={`fa-solid ${showNewConfirmPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowNewConfirmPassword(!showNewConfirmPassword)}} />
                                        </span>
                                    }
                                </div>
                                { formik.touched.confirm_new_password && <p className="mt-1 text-sm text-red-500">{formik.errors.confirm_new_password}</p> }
                            </div>
                            <div className='py-5'>
                                <button className='btn btn-primary submit-btn'>Save & Continue</button>
                                <div className='btn link-btn link-dark-btn ms-3' onClick={onClose}>Cancel</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}


export default UpdatePasswordModal