import React from 'react'
import { Modal, ModalBody } from "reactstrap"

import closeIcon from "../../../../assets/images/modal-close-icon.svg"

import { freePlanFeatures } from '../../../../constants/planFeatures';
import { proPlanFeatures } from '../../../../constants/planFeatures';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const AccountPlanFeaturesModal = ({ visible, onClose } ) => {
  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody>
            <div className='content text-center'>
                <div className='title'>
                  <h3 className='mt-2 mb-5 fw-regular ft-default'>Sqillup Plans Features</h3>
                  <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
                </div>
                <div className='desp'>
                  <div className='container-fluid'>
                    <div className='row'>
                      
                      <div className='col-md-6'>
                        <div className='features-content border-primary-900 p-4 rounded-lg bg-white'>
                          <h4 className='btn bg-primary-900 no-event type-btn'>Free</h4>
                          <ul className='list'>
                          { freePlanFeatures.map(item => {
                            return (
                                <li className='d-flex align-items-center my-4'>
                                  <FontAwesomeIcon className='tick-icon me-3' icon="fas fa-check-circle" />
                                  <p className='text-gray-950 fw-regular text-normal'>{item}</p>
                                </li>
                              )
                          })}
                          </ul>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='features-content border-primary-900 p-4 rounded-lg bg-white'>
                          <h4 className='btn bg-primary-900 no-event type-btn'>Pro</h4>
                          <ul className='list'>
                            { proPlanFeatures.map(item => {
                              return (
                                  <li className='d-flex align-items-center my-4'>
                                    <FontAwesomeIcon className='tick-icon me-3' icon="fas fa-check-circle" />
                                    <p className='text-gray-950 fw-regular text-normal'>{item}</p>
                                  </li>
                                )
                            })}
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
  )
}

export default AccountPlanFeaturesModal