import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk';

import { useFormik } from 'formik'
import { addBillingInfo as onAddBillingInfo, updateBillingInfo as onUpdateBillingInfo } from '../../store/billing/actions';

const AddBilling = ({isEdit, onClose, onSubmitData}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const { user, bills, get_bill_success, update_bill_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    bills: state.BillingReducer.bills,
    get_bill_success: fulfilled(state, "ADD_BILLING_INFO"),
    update_bill_success: fulfilled(state, "UPDATE_BILLING_INFO")
  })) 

  const initialValues = {
    first_name: "",
    last_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    country: "",
    postal_code: "",
    holder_name: ""
  }

  const onSubmit = values => {

    let data = {
        user_id: user.id,
        first_name:values.first_name,
        last_name: values.last_name,
        address_line_1: values.address_line_1,
        address_line_2:values.address_line_2,
        city: values.city,
        country: values.country,
        postal_code: values.postal_code
    }

    if(!isEdit) {
        dispatch(onAddBillingInfo(data))
    }
    else {
        let bill = bills[0]
        data.id = bill.id
        dispatch(onUpdateBillingInfo(data))
    }

  }

  const validate = values => {

    let errors = {}

    if(!values.first_name) {
        errors.first_name = "First Name is required!"
    }

    if(!values.last_name) {
        errors.last_name = "Last Name is required!"
    }

    if(!values.address_line_1) {
        errors.address_line_1 = "Address Line 1 is required!"
    }

    if(!values.address_line_2) {
        errors.address_line_2 = "Address Line 2 is required!"
    }

    if(!values.city) {
        errors.city = "City is required!"
    }

    if(!values.country) {
        errors.country = "Country is required!"
    }

    if(!values.postal_code) {
        errors.postal_code = "Postal Code is required!"
    }

    return errors

  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  useEffect(() => {
    dispatch(clean("ADD_BILLING_INFO"))
    if(get_bill_success) {
        let currentUrlParams = new URLSearchParams(location.search);
        currentUrlParams.set('sid', 4)
        navigate( { pathname: "/parent/payment", search: currentUrlParams.toString() }, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_bill_success])


  useEffect(() => {
    dispatch(clean("UPDATE_BILLING_INFO"))
    if(update_bill_success) {
        onSubmitData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update_bill_success])

  useEffect(() => {
    if(isEdit) {
        let bill = bills[0]
        Object.keys(bill).forEach(function(key) {
            if(key in formik.values) {
                formik.setFieldValue(key, bill[key])
            }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit])

  return (
    <div className='add-billing-card-wrapper'>
        <form name="addBillingInfo" onSubmit={formik.handleSubmit}>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="first_name">First Name<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="first_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.first_name} />
                    </div>
                </div>
                { formik.touched.first_name && <p className="mt-1 text-sm text-red-500">{formik.errors.first_name}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="last_name">Last Name<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="last_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.last_name} />
                    </div>
                </div>
                { formik.touched.last_name && <p className="mt-1 text-sm text-red-500">{formik.errors.last_name}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="address_line_1">Address line 1<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="address_line_1" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.address_line_1} />
                    </div>
                </div>
                { formik.touched.address_line_1 && <p className="mt-1 text-sm text-red-500">{formik.errors.address_line_1}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="address_line_2">Address line 2<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="address_line_2" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.address_line_2} />
                    </div>
                </div>
                { formik.touched.address_line_2 && <p className="mt-1 text-sm text-red-500">{formik.errors.address_line_2}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="city">City<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="city" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.city} />
                    </div>
                </div>
                { formik.touched.city && <p className="mt-1 text-sm text-red-500">{formik.errors.city}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="country">Country<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="country" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.country} />
                    </div>
                </div>
                { formik.touched.country && <p className="mt-1 text-sm text-red-500">{formik.errors.country}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="postal_code">Postal Code<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="number" className='form-control' name="postal_code" pattern='[0-9]' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.postal_code} />
                    </div>
                </div>
                { formik.touched.postal_code && <p className="mt-1 text-sm text-red-500">{formik.errors.postal_code}</p> }
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary submit-btn'>Next</button>
                { isEdit && <div className='ms-4 btn btn-secondary submit-btn' onClick={onClose}>Back</div> }
            </div>
        </form>
    </div>
  )
}


export default AddBilling