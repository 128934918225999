import React, { useState } from 'react'

import PaymentVisaCardImg from '../../../assets/images/payment-card-visa-img.png'
import PaymentStripeCardImg from '../../../assets/images/payment-card-stripe-img.png'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const PaymentCard = ({ key, index, card, onDelete } ) => {

  const [ isDropDownExpanded, setDropDownExpanded ] = useState(false)

  const getCardNumber = (number) => {
    return number.substr((number.length-4), number.length)
  }

  return (
    <div className='payment-card'>
        <div className='title-box bg-primary-900 d-flex align-items-center'>
          <div className='text-base ft-default text-white'>payment method</div>
          <FontAwesomeIcon className={`ms-auto text-white fs-6 pointer ${index === 0 ? "d-none" : ""}`} icon="fa-solid text-h4 fa-trash" onClick={() => {onDelete(card.id)}} />
        </div>
        <div className={`content ${isDropDownExpanded ? "bg-white shadow" : "bg-gray-100"} d-flex`}>
          <div className='mx-2 mt-3'>
            <div className="check-item">
              <div className='inner'></div>
            </div>
          </div>
          <div className='mx-2'>
            <img className='card-img' src={ card.card_type === "visa" ? PaymentVisaCardImg : PaymentStripeCardImg } alt="" />
          </div>
          <div className='mx-2'>
            <div className='mb-1 text-h6'>Ending in {getCardNumber(card.card_number)}</div>
            <div className='mb-1 text-h6 text-gray-700'>Expires in {card.expiry_date}</div>
            <div className='mb-2 text-base text-gray-700'>{card.holder_name}</div>
            { isDropDownExpanded && <input type="text" className='form-control user-select-none' placeholder='Enter cvc' value={card.cvc} /> }
          </div>
          <div className='ms-auto me-2'>
            <FontAwesomeIcon className='ms-auto text-gray-700 fs-6 pointer' icon={`fa-solid text-h4 ${isDropDownExpanded ? "fa-angle-up" : "fa-angle-down"}`} onClick={() => {setDropDownExpanded(!isDropDownExpanded)}} />
          </div>
        </div>
    </div>
  )
}


export default PaymentCard