import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { sessionData } from '../../constants/sessiondata'
import { restrictedRoutesAfterSignedIn } from '../allRoutes'

const LoggedMiddleware = ({children}) => {
  
  const location = useLocation()
  const auth = (sessionData.getAuthUser() && sessionData.getParentId() && sessionData.getEmailVerified())
  const isRestricted = restrictedRoutesAfterSignedIn.some(item => item.path === location.pathname)
  return (
    <>
    { auth ? (
      <>
      { isRestricted ? (
        <Navigate to="/parent/dashboard" />
      ) : (
        children
      )}
      </>
    ) : (
      children
    )}
    </>
  )

}

export default LoggedMiddleware