import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_REWARD, VIEW_REWARD_CATEGORY, DELETE_REWARD, UPDATE_REWARD, CREATE_REWARD } from './actionTypes'
import { viewRewardSuccess, viewRewardFailed, viewRewardCategorySuccess, viewRewardCategoryFailed, deleteRewardSuccess, deleteRewardFailed, updateRewardSuccess, updateRewardFailed, createRewardSuccess, createRewardFailed } from './actions'

import { viewRewardApi, viewRewardCategoryApi, deleteRewardApi, updateRewardApi, createRewardApi } from "../../helpers/api/api"


function* viewReward({ payload:details, meta }) {
    try {
        const response = yield call(viewRewardApi, details)
        yield put(viewRewardSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewRewardFailed(message, meta))
    }
}

function* viewRewardCategory({ meta }) {
    try {
        const response = yield call(viewRewardCategoryApi)
        yield put(viewRewardCategorySuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewRewardCategoryFailed(message, meta))
    }
}

function* deleteReward({ payload: data, meta }) {
    try {
        const response = yield call(deleteRewardApi,data)
        yield put(deleteRewardSuccess({response, data}, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(deleteRewardFailed(message, meta))
    }
}

function* createReward({ payload: data, meta }) {
    try {
        const response = yield call(createRewardApi,data)
        yield put(createRewardSuccess({response, data}, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(createRewardFailed(message, meta))
    }
}

function* updateReward({ payload: data, meta }) {
    try {
        const response = yield call(updateRewardApi,data)
        yield put(updateRewardSuccess({response, data}, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateRewardFailed(message, meta))
    }
}

function* RewardSaga() {
    yield takeEvery(VIEW_REWARD, viewReward)
    yield takeEvery(VIEW_REWARD_CATEGORY, viewRewardCategory)
    yield takeEvery(CREATE_REWARD, createReward)
    yield takeEvery(UPDATE_REWARD, updateReward)
    yield takeEvery(DELETE_REWARD, deleteReward)
}

export default RewardSaga

