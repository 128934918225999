import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled } from 'redux-saga-thunk';

import { Modal, ModalBody } from "reactstrap"

import closeIcon from "../../../assets/images/modal-close-icon.svg"

import { logoutUser as onLogoutUser } from '../../../store/auth/login/actions'


import _ from 'underscore'


const SwapAccountModal = ({ visible, onClose }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedStudent, setSelectedStudent ] = useState({})

  const { students, get_student } = useSelector(state => ({
    students: state.StudentReducer.student,
    get_student: fulfilled(state, "VIEW_STUDENT")
  })) 

  const handleStudentSelect = (student) => {
    setSelectedStudent(student)
  }

  const handleSwapAccount = () => {
    dispatch(onLogoutUser(navigate, 'student'))
  }

  useEffect(() => {
    if(visible) {
      setSelectedStudent({})
    }
  }, [visible])

  return (
    <Modal className="swap-account-modal" isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody className='rounded-lg shadow'>
            <div className='swap-account-wrapper bg-gray-100'>
                <div className='content'>
                  <div className='title'>
                      <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
                  </div>
                  <form className="sign-in-form" name="signInForm">
                    <div className='desp'>
                      <h4 className='text-cool-gray-950'>Select Student to go to their account</h4>
                      <div className='student-list'>
                      { get_student && visible && students.map((student, index) => (
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="student" id={`student${student.id}`} onChange={() => {handleStudentSelect(student)}}  />
                          <label className="form-check-label" htmlFor={`student${student.id}`}>{student.full_name}</label>
                        </div>
                        ))
                      }
                      </div>
                      <div className={`btn btn-big btn-primary goto-btn ${_.isEmpty(selectedStudent) ? "disabled" : ""}`} onClick={handleSwapAccount} >Go to student dashboard</div>
                    </div>
                  </form>
                </div>
            </div>
        </ModalBody>
    </Modal>
  )
}


export default SwapAccountModal