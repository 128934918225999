import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean } from 'redux-saga-thunk'

import { useFormik } from 'formik'

import growthBlockImg  from '../../../assets/images/growth-block-img.png'

import GrowthScoreCard from './GrowthScoreCard'
import SelectInputOverlap from '../../../components/Common/SelectInputOverlap'

import { viewSubject as onViewSubject } from '../../../store/subject/actions';
import { viewExamType as onViewExamType } from '../../../store/actions';
import { viewChapters as onViewChapters } from '../../../store/actions';

const GrowthScore = props => {

  const dispatch = useDispatch()

  const [ isScoreSelected, setScoreSelected ] = useState(false)
  const [ isChapterVisible, setChapterVisible ] = useState(false)

  const [ selectedChapter, setSelectedChapter ] = useState([])

  const { user, subject, exam_type, chapters, get_subject_success, get_exam_type_success, get_chapter_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    subject: state.SubjectReducer.subject,
    exam_type: state.LessonScoreReducer.exam_type,
    chapters: state.LessonScoreReducer.chapters,
    get_subject_success: fulfilled(state, "VIEW_SUBJECT"),
    get_exam_type_success: fulfilled(state, "VIEW_EXAM_TYPE"),
    get_chapter_success: fulfilled(state, "VIEW_CHAPTERS")
}))

  const initialValues = {
    subject_id: "",
    chapter_id: "",
    exam_board_id: ""
  } 

  const validate = values => {

    let errors = {}

    if(!values.subject_id) {
      errors.subject_id = "Subject ID required!"
    }

    if(!values.chapter_id) {
        errors.chapter_id = "Chapter required!"
    }

    if(!values.exam_board_id) {
        errors.exam_board_id = "Exam required!"
    }
    
    return errors

  }

  const onSubmit = values => {

  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  const handleOnSelectSubject = (id) => {
    formik.setFieldValue("subject_id", id)
  }

  const handleOnSelectExamType = (id) => {
    formik.setFieldValue("exam_board_id", id)
    setChapterVisible(true)
    
  }

  const handleOnSelectChapter = (id) => {
    formik.setFieldValue("chapter_id", id)
  }

  

  useEffect(() => {
    dispatch(onViewSubject())
    dispatch(onViewExamType())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(formik.values.subject_id && formik.values.exam_board_id) {

      let data = {
        subject_id: formik.values.subject_id,
        exam_board_id: formik.values.exam_board_id
      }
      dispatch(onViewChapters(data))
      //setChapterVisible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.subject_id, formik.values.exam_board_id])

  useEffect(() => {
    if(get_chapter_success) {
      console.log(chapters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_chapter_success])

  return (
    <div className='growth-inner-wrapper'>
        <div className='row'>
          <div className='col-sm-7'>
            <div className='left-box bg-white h-100'>
              { !isScoreSelected ? 
                <div className='no-results'>
                  <div className='img-container'>
                    <img src={growthBlockImg} alt="" />
                  </div>
                  <div className='desp-box'>
                    <h6 className='title'>To check the score select the given fields.</h6>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum reprehenderit est assumenda doloribus accusantium nobis </p>
                  </div>
                </div> : 
                <GrowthScoreCard />
              }
            </div>
          </div>
          <div className='col-sm-5'>
            <div className='right-box bg-white'>
              { get_subject_success && get_exam_type_success &&
                <form className="create-lesson-form" name="createLessonForm" onSubmit={formik.handleSubmit}>
              
                  <div className='select-reward-box'>
                    <SelectInputOverlap
                        fieldType="name"
                        fieldLabel="selectScoreSubject"
                        data={subject}  
                        placeholder="Select Subject"
                        design="primary"
                        onChange={handleOnSelectSubject}
                    />
                  </div>

                  <div className='select-reward-box'>
                    <SelectInputOverlap
                        fieldType="title"
                        fieldLabel="selectScoreEXamType"
                        data={exam_type}  
                        placeholder="Select Exam type"
                        design="primary"
                        onChange={handleOnSelectExamType}
                    />
                  </div>
                  { isChapterVisible &&  

                      <div className='select-reward-box'>
                        <SelectInputOverlap
                            fieldType="name"
                            fieldLabel="selectScoreChapter"
                            data={subject}  
                            placeholder="Select Chapter"
                            design="primary"
                            onChange={handleOnSelectChapter}
                        />
                      </div>
                  }

                  <div className='mt-5'>
                    <button className='btn btn-primary submit-btn'>Apply</button>
                  </div>
                
                </form>
              }
            </div>
          </div>
        </div>
    </div>
  )
}

export default GrowthScore