import React from 'react'
import { Navigate } from 'react-router-dom'

import { sessionData } from '../../constants/sessiondata'

const AuthMiddleware = ({children}) => {
  const auth = (sessionData.getAuthUser() && sessionData.getParentId() && sessionData.getEmailVerified())
  return (
    <>
      {
        auth ? (
          children
        ) : (
           <Navigate to="/sign-in" />
        )
      }
    </>
  )

}

export default AuthMiddleware