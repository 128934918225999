import React, { useState, useEffect } from 'react'
import { fulfilled, clean } from "redux-saga-thunk"

import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Alert } from "reactstrap"
import OTPInput from "otp-input-react";

import { verifyOtp as onVerifyOtp, resendOtp as onResendOtp } from '../../../../store/auth/otp/actions';
import { sessionData } from '../../../../constants/sessiondata';

const OtpVerifyAccount = ( { onClose } ) => {

    const dispatch = useDispatch()

    const [otpData, setOtpData] = useState("");

    const { user, error, get_verify_success } = useSelector(state => ({
        user: state.LoginReducer.user,
        error: state.OtpReducer.error,
        get_verify_success: fulfilled(state, "VERIFY_OTP")
    }))

    const initialValues = {
        otp:""
    }

    const onSubmit = (values) => {
        let data = {
            email: user.email,
            otp: parseInt(values.otp)
        }
        dispatch(onVerifyOtp(data))
    }

    const validate = (values) => {
        
        let errors = {}

        if(values.otp.length !== 4) {
            errors.otp = "OTP requires 4 digits!"
        }
        return errors

    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    const handleOtpCode = (e) => {
        e.preventDefault()
        let data = {
            email: user.email
        }
        dispatch(onResendOtp(data))
    }

    useEffect(() => {
        formik.setFieldValue("otp", otpData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otpData])

    useEffect(() => {
        dispatch(clean("VERIFY_OTP"))
        if(get_verify_success) {
            sessionData.setEmailVerified("on")
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_verify_success])


  return (
    <div className='verify-card card lux rounded-lux'>
        <div className='card-body'>
            <div className="form-container text-center">
                <h2 className="text-gray-980 fw-semibold ls--2 mb-3">Verfication</h2>
                <p className="text-gray-980 text-normal op-50">Please check your email inbox for a 4 digit code</p>
                <div className='mt-5'>
                    <p className='text-base text-gray-980'>Enter the OTP below</p>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='mt-3'>
                        {   (error) && ( <Alert color="danger">{error}</Alert>)}
                        </div>
                        <div className='otp-wrapper d-flex justify-content-between align-items-center'>
                            <OTPInput className="otp-box" value={otpData} onChange={setOtpData} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                        </div>
                        { formik.touched.otp && <p className="mt-1 text-sm text-red-500">{formik.errors.otp}</p> }
                        <div className="mt-4">
                            <button type="submit" className="btn btn-special w-100 text-uppercase ls--15">Verify</button>
                        </div>
                    </form>
                </div>
                <div className="mt-3 text-center">
                    <span className="ft-head fw-medium text-sm ls--25">Didn’t received the code?</span> <span href="/#" onClick={(e) => {handleOtpCode(e)}} className="text-blue-500 fw-medium ft-head text-sm pointer">Resend Code</span>
                </div>
            </div>
        </div>
    </div>
  )
}


export default OtpVerifyAccount