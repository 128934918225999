import {
    CONTACT_ADMIN_SUCCESS, CONTACT_ADMIN_FAILED
  } from './actionTypes'
  
  
  const INIT_STATE = {
    loading: false,
    message: null,
    error: null
  }
  
  const ContactAdminReducer = (state = INIT_STATE, action) => {
  
      switch (action.type) {
        
        case CONTACT_ADMIN_SUCCESS: {
          return {
            ...state,
            loading: false,
            message: action.payload
          }
        }
        case CONTACT_ADMIN_FAILED: {
          return {
            ...state,
            error: action.payload,
          }
        }
        default:
          return state
      }
  
  }
  
  export default ContactAdminReducer;